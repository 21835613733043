import { api, escalateError, getResponseData } from './index';

export default class ProjectApi {
    static getProjects() {
        return api.get('/project')
                .then(getResponseData)
                .catch(escalateError);
    }

    static getQuestions(type) {
        return api.get(`/landin/question/${type}`)
            .then(getResponseData)
            .catch(escalateError);
    }
}
