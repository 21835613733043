/* eslint-disable react/prop-types */
// eslint-disable-next-line no-unused-vars
import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@rmwc/button';
import { Card, CardMedia } from '@rmwc/card';
import {
  mdiCalendarBlankOutline,
  mdiCurrencyUsd
} from '@mdi/js';
import Icon from '@mdi/react';

import styles from './cardLanding.module.css';

const Cards = ({ projectData = {}, isMyProject }) => {
  const { project = {}, mainPicture = [], projectId } = projectData;
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });
  const beginningDate = new Date(project.beginningDate).toLocaleDateString('US');
    return <>
      <Card className={styles['card-container']}>
        <CardMedia
          className={styles.img}
          sixteenByNine
          style={{ backgroundImage: `url(${mainPicture[0] || 'https://powen.mx/wp-content/uploads/2021/05/34-1080x675.jpg'})` }}/>

        <div className={styles.top__content}>
          <h1 style={{ fontSize: 'calc(1em + 0.7vw)' }}>{project.projectName}</h1>
          <p>Estado actual: <span className={styles.status}>Financiación</span></p>
        </div>
        <div className={styles.secondCards}>
          <p className={styles.p} style={{ width: `${project.raisedMoney}%` }}></p>
          <p className={styles.p} style={{ width: `${100 - project.raisedMoney}%` }}></p>
        </div>
        <p className={styles['raised-money']}>{project.raisedMoney} % financiado</p>
        <hr/>
        <div className={styles.top__content}>
          <h2 style={{ fontSize: 'calc(1em + 0.7vw)', margin: '0.7rem' }}>{project.annualRest}%</h2>
          <p className={styles.profitability}>Rentabilidad estimada anual</p>
        </div>
        <hr/>
        <div className={styles.text3Cards}>
          <div>
            <p>
              <Icon className={styles.icon} path={mdiCalendarBlankOutline} size={1}/>
              <span>inicio programado:</span> {`${beginningDate}`}
            </p>
            <p>
              <Icon className={styles.icon} path={mdiCurrencyUsd} size={1}/>
              <span>inversion total: </span> {`${formatter.format(project.totalCost)}`}
            </p>
          </div>
        </div>
        <h2 style={{ marginTop: '1rem' }}>Precio por panel: </h2>
        <p className={styles['panel-cost']}>{formatter.format(project.panelCost)}</p>
        <Button className={styles.button} label={'Invertir'}
                style={{ display: isMyProject ? 'none' : '' }} onClick={() => {
            window.location.href = (`https://platform.farmenergy.app/description/${projectId}`);
            }} raised/>
      </Card>
    </>;
};

Cards.propTypes = {
  projectData: PropTypes.object,
  isMyProject: PropTypes.bool,
};

export default Cards;
