/* eslint-disable no-console */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { Button } from '@material-ui/core';

import styles from './projects.module.css';
import Slider from 'react-slick';
import Card from '../Cards/landing/CardsLanding';
import ProjectApi from '../../api/ProjectApi';

const Projects = () => {
  const [projects, setProjects] = useState([]);

  console.log(projects);
  useEffect(() => {
    // eslint-disable-next-line max-statements-per-line
    ProjectApi.getProjects().then((data) => { setProjects(data.concat(...data)); });
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 939,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 734,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
    return (
      <div className={styles.rootProjects}>
        <div className={styles.titleProjects}>
          <h1>
            Proyectos más recientes para invertir
          </h1>
        </div>
        <div>
          <div className={styles.cardsProjects} >
            <Slider {...settings} className={styles.slydercs}>
              {
              (Array.isArray(projects) ? projects : [])
              .map((project, pos) => <Card key={pos} projectData={project}></Card>)
            }
            </Slider>
          </div>
        </div>
        <div className={styles.contentButtonProjects}>
          <Button className={styles.buttonProjects} variant={'outlined'}
                  onClick = {() => {
                    window.location.href = 'https://platform.farmenergy.app/projects';
                  }}>Ver todos los proyectos</Button>
        </div>
      </div>
    );
};

export default Projects;

