import React from 'react';
import styles from './Questions.module.css';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const AccordionQuestion = (props1) => {
    return <>
      <div className={styles.Accordion}>
        <Accordion>
          <AccordionSummary
              expandIcon={<ExpandMoreIcon/>}
              aria-controls={'panel1a-content'}
              id={'panel1a-header'}
              >
            <span></span>
            <h3>{props1.name}</h3>
          </AccordionSummary>
          <AccordionDetails>
            <p>
              {props1.desc}
            </p>
          </AccordionDetails>
        </Accordion>
      </div>

    </>;
};

export default AccordionQuestion;
