import React from 'react';
import styles from './completeProjects.module.css';
import complete1 from './../../resources/imgs/complete1.png';
import complete2 from './../../resources/imgs/complete2.png';
import complete3 from './../../resources/imgs/complete3.png';
import Slider from 'react-slick';
import Card from '../Cards/CardsComplete';

const CompleteProjects = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
    return (
      <div className={styles.rootComplete}>
        <div className={styles.titleComplete}>
          <h1>Proyectos Finalizados </h1>
        </div>
        <div className={styles.contentComplete}>
          <Slider {...settings} className={styles.slydercs}>

            <Card
                    src={complete1}
                    alt={'Imagen de una casa'}
                    firstText={'Fabrica Colparques'}
                    secondText={'10.58%'}
                    thirdText={'Renta anual actual'}
                />
            <Card
                    src={complete2}
                    alt={'Imagen de una casa'}
                    firstText={'Centro internacional de artes'}
                    secondText={'13.08%'}
                    thirdText={'Renta anual actual'}
                />

            <Card
                    src={complete3}
                    alt={'Imagen de una casa'}
                    firstText={'Centro internacional de artes'}
                    secondText={'13.08%'}
                    thirdText={'Renta anual actual'}
                />
            <Card
                    src={complete2}
                    alt={'Imagen de una casa'}
                    firstText={'Centro internacional de artes'}
                    secondText={'13.08%'}
                    thirdText={'Renta anual actual'}
                />

          </Slider>
        </div>
      </div>
    );
};

export default CompleteProjects;
