import React from 'react';
import Navbar from '../navbar/Navbar';
import StickyFooter from '../footer/StickyFooter';
import About from '../aboutUs/AboutUs';

const aboutUs = () => {
    return (
      <>
        <Navbar />
        <About />
        <StickyFooter />
      </>
    );
};

export default aboutUs;
