import React from 'react';
import home from './components/sites/LandingPageHome';
import about from './components/sites/aboutUs';
import questions from './components/sites/Questions';
import privacy from './components/sites/privacyPolicy';
import terms from './components/sites/termsConditions';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const App = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path={'/'} component={home} />
      <Route exact path={'/about-us'} component={about} />
      <Route exact path={'/questions'} component={questions} />
      <Route exact path={'/privacy'} component={privacy} />
      <Route exact path={'/terms'} component={terms} />
    </Switch>
  </BrowserRouter>
);

export default App;
