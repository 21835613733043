/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import imageBanner from './../../resources/imgs/questions.svg';
import styles from './Questions.module.css';
import AccordionQuestion from './Accordion';
import { TextField, IconButton } from '@material-ui/core';
import { SearchOutlined } from '@material-ui/icons';
import ProjectApi from '../../api/ProjectApi';
import analize from './../../resources/imgs/analize.png';

const Questions = () => {
    const [questions, setQuestions] = useState([]);
    const [search, setSearch] = useState('');
    useEffect(() => {
      ProjectApi.getQuestions('questions')
      .then((resp) => {
        setQuestions(resp);
      })
      .catch((e) => {
        setQuestions([]);
      });
    }, []);

    const filterData = questions.filter((element) =>
        (element.info.question.toLowerCase().includes(search.toLowerCase())
        || element.info.answer.toLowerCase().includes(search.toLowerCase())));

    return (
      <div className={styles.rootQuestions}>
        <div className={styles.rootBanner}>
          <h1 className={styles.title}>Preguntas frecuentes</h1>
          <div className={styles.imgBanner}>
            <img src={imageBanner} alt={'Banner'} />
          </div>
          <div className={styles.textBanner}>
            <h1 className={styles.titleWeb}>Preguntas frecuentes</h1>
            <TextField
                className={styles.search}
                fullWidth
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                id={'standard-bare'}
                variant={'outlined'}
                placeholder={'Buscar por terminos'}
                InputProps={{
                  endAdornment: (
                    <IconButton>
                      <SearchOutlined />
                    </IconButton>
                  ),
                }}
              />
          </div>
        </div>
        <div className={styles.AccordionAll}>
          {
            filterData?.length > 0
              ? filterData.map(({ info, idQuestion }) => (
                <AccordionQuestion
                  name={info?.question}
                  desc={info?.answer}
                  key={idQuestion}
                />
              ))
            : <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
              <p>Sin resultados para la busqueda</p>
              <img src={analize} style={{ height: '250px', width: '250px' }}/>
            </div>
          }
        </div>
      </div>
    );
};

export default Questions;
