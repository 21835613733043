/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { Button } from '@material-ui/core';
import styles from './aboutUs.module.css';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ProjectApi from '../../api/ProjectApi';
import analize from './../../resources/imgs/analize.png';
import image from './../../resources/imgs/imageAbout.png';

const aboutUs = () => {
    const [aboutUs, setAboutUs] = useState([]);
    const [aboutUsPartner, setAboutUsPartner] = useState([]);

    useEffect(() => {
      ProjectApi.getQuestions('aboutUs')
      .then((resp) => {
        setAboutUsPartner(resp.filter(({ info }) => info.isPartner));
        setAboutUs(resp.filter(({ info }) => !info.isPartner));
      })
      .catch((e) => {
        setAboutUs([]);
      });
    }, []);

    return (
      <div className={styles.rootAbout}>
        <h1>Que es Farm Energy?</h1>
        <div className={styles.firstTextAbout}>
          <p>
            <span>Farm Energy </span>
            es una plataforma de  inversión de recursos privados  en proyectos de energía renovable. </p>
          <p>Con nosotros puedes financiar tu proyecto  pero también puedes participar en otros proyectos
            e invertir desde un panel solar en adelante y conseguir dividendos bastante atractivos con la energía producida. </p>
          <p>Como granjero puedes tener participación en múltiples proyectos al mismo tiempo,
            monitorear su producción y conocer tus ganancias.</p>
          <p>Vendemos energía limpia y distribuimos las utilidades entre los granjeros según su participación.</p>
          <p>Como Socio puedes  postular tu proyecto de tipo  hogares, Empresa o Industria, hacemos un estudio
            de viabilidad , factibilidad  costos y luego puedes financiar tu proyecto.
          </p>

          <h3>Beneficios:</h3>
          <p>Obtén una tarifa de energía más barata</p>
          <ul>
            <li>
              Con la instalación de un sistema fotovoltaico, obtendrás sustanciales
              ahorros en los pagos mensuales de tus facturas de energía.</li>
          </ul>
          <p>Bajo costo de inversión:</p>
          <ul>
            <li>
              La instalación del sistema de generación de energía fotovoltaica será
              llevada a  cabo con bajo costo de inversión, y del monto que pagues mensualmente
              por tu energía, se tomará el dinero para el mantenimiento del mismo.
            </li>
          </ul>
        </div>
        <div>
          <img className={styles.image} src={image}/>
        </div>
        <div className={styles.secondTextAbout}>
          <p>Vendemos energia limpia y distribuimos las utilidades entre los granjeros según su participación</p>
        </div>
        <h1> ¿Cómo funciona para granjeros?</h1>
        {
          aboutUs?.length > 0
            ? aboutUs.map((data, idx) => (
              <div className={styles.Accordion} key={data?.idQuestion}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon /> }
                    aria-controls={'panel1a-content'}
                    id={'panel1a-header'}
                  >
                    <span>{(idx + 1)}</span>
                    <h3>{data?.info?.question}</h3>
                  </AccordionSummary>

                  <AccordionDetails>
                    <p>
                      {data?.info?.answer}
                    </p>
                  </AccordionDetails>
                </Accordion>
              </div>
            ))
            : <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
              <p>Sin contenido por el momento</p>
              <img src={analize} style={{ height: '250px', width: '250px' }}/>
            </div>
        }
        <h1> ¿Cómo funciona para socios?</h1>
        {
          aboutUsPartner?.length > 0
            ? aboutUsPartner.map((data, idx) => (
              <div className={styles.Accordion} key={data?.idQuestion}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon /> }
                    aria-controls={'panel1a-content'}
                    id={'panel1a-header'}
                  >
                    <span>{(idx + 1)}</span>
                    <h3>{data?.info?.question}</h3>
                  </AccordionSummary>

                  <AccordionDetails>
                    <p>
                      {data?.info?.answer}
                    </p>
                  </AccordionDetails>
                </Accordion>
              </div>
            ))
            : <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
              <p>Sin contenido por el momento</p>
              <img src={analize} style={{ height: '250px', width: '250px' }}/>
            </div>
        }

        <div className={styles.contentButtonAbout}>
          <Button className={styles.buttonAbout} href={'/questions'} variant={'outlined'}>Ir a preguntas frecuentes</Button>
        </div>
      </div>
    );
};

export default aboutUs;
