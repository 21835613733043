/* eslint-disable max-len */
import React from 'react';
import imageBanner from './../../resources/imgs/banner.jpg';
import sun from './../../resources/imgs/sun.png';
import styles from './banner.module.css';

const Banner = () => {
    return (
      <div className={styles.rootBanner}>
        <div className={styles.textBanner}>
          <h1>Haz parte de proyectos de energía solar y obtén la mejor rentabilidad</h1>
          <h3>Ahora puedes invertir en proyectos de energias renovables facilmente,
            en farmenergy puedes hacerlo rapidamente, de forma segura, simular tus ganancias,
            analizar proyectos e invertir.
          </h3>
        </div>
        <div className={styles.imgBanner}>
          <img src={imageBanner} alt={'Banner'} />
          <img src={sun} alt={'Banner'} />
        </div>
      </div>
    );
};

export default Banner;
