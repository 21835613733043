/* eslint-disable max-len */
import React from 'react';
import { Button } from '@material-ui/core';
import imgMember from '../../resources/imgs/member.png';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import styles from './member.module.css';

const Member = () => {
    return (
      <div className={styles.rootMember}>
        <div className={styles.titleMember}>
          <h1>Conviértete en nuestro socio</h1>
        </div>
        <div className={styles.contentMember}>
          <div className={styles.imageMember}>
            <img src={imgMember} alt={'socio'} />
          </div>
          <div className={styles.contentTextMember}>
            <span>¿Tienes un espacio disponible como cubiertas ,terrazas o campos para proyectos de energías renovables, pero te preocupa la inversión inicial y los costos de mantenimiento?</span>
            <span>¡Consume energía limpia sin costos de inversión ni mantenimiento!</span>
            <Button startIcon={<WhatsAppIcon/>} variant={'contained'} className={styles.buttonMember} href={'https://wa.me/3118413929/?text=Quiero%20saber%20mas%20de%20FARM%20ENERGY'} target={'_blank'}>Contáctanos</Button>
          </div>
        </div>
        <div className={styles.columns}>
          <div>
            <h3>Obtén una tarifa de energía más barata</h3>
            <p>Con la instalación de un sistema fotovoltaico, obtendrás sustanciales ahorros en los
              pagos mensuales de tus facturas de energía.
            </p>
          </div>
          <div>
            <h3>Bajo costo de inversión</h3>
            <p>La instalación del sistema de generación de energía fotovoltaica será llevada a
              cabo con bajo costo de inversión, y del monto que pagues mensualmente por tu
              energía, se tomará el dinero para el mantenimiento del mismo.
            </p>
          </div>
          <div>
            <h3>Cámbiate de comercializadora y toma el control de tus consumos</h3>
            <p>Con FarmEnergy  podrás tomar el control de tus consumos energéticos,
              visualizando en tiempo real, conociendo de dónde viene la energía que consumes y
              estimados de sus precios.
            </p>
          </div>
        </div>
      </div>
    );
};

export default Member;
