import React from 'react';
import {
  AppBar,
  Toolbar,
  Button,
  Menu,
  Typography,
  Link
} from '@material-ui/core';

import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Logo from './../../resources/imgs/logo2.png';
import './navbar.css';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role={'tabpanel'}
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const NavBarOptions = () => {
  return (
    <div
    className={'button'}
    style={{ display: 'flex', flexDirection: 'row' }}
  >
      <Link color={'inherit'} className={'linksNavBar'} href={'/about-us'} underline={'none'}>
        Quienes somos
      </Link>
      <Link color={'inherit'} className={'linksNavBar2'} href={'#footer'} underline={'none'}>
        Contáctanos
      </Link>
      <Button className={'buttonNavBar'} href={'https://platform.farmenergy.app/'}>Iniciar sesión</Button>
      <Button className={'buttonNavBar2'} href={'https://platform.farmenergy.app/signup'}>Crear cuenta</Button>
    </div>
    );
};

const NavBarOptionsResponsive = () => (
  <div
    className={'buttonResponsive'}
    style={{ display: 'flex', flexDirection: 'column' }}
  >
    <Button className={'buttonNavBarResponsive'} href={'https://platform.farmenergy.app/'}>Iniciar sesión</Button>
    <Button className={'buttonNavBar2Responsive'} href={'https://platform.farmenergy.app/signup'} >Crear cuenta</Button>
    <Link
      color={'inherit'}
      className={'linksNavBar2Responsive'}
      href={'#footer'} underline={'none'}>
      Contáctanos
    </Link>
    <Link
      color={'inherit'}
      className={'linksNavBarResponsive'}
      href={'/about-us'} underline={'none'}>
      Quienes somos
    </Link>

  </div>
);

const NavBar = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <AppBar className={'content'}>
        <Toolbar>
          <Typography variant={'h6'} className={'titleNavBar'}>
            <Link href={'/'} underline={'none'}>
              <img src={Logo} alt={'logo'} className={'logoNavbar'} />
            </Link>
          </Typography>
          <div className={'menu'}>
            <IconButton aria-controls={'simple-menu'} aria-haspopup={'true'} onClick={handleClick}>
              <MenuIcon />
            </IconButton>
            <Menu
              id={'simple-menu'}
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <div className={'mobile'} style={{ display: 'flex', flexDirection: 'row' }}>
                {NavBarOptionsResponsive()}
              </div>
            </Menu>
          </div>
          <div className={'desktop'}>{NavBarOptions()}</div>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default NavBar;
