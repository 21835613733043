import React from 'react';
import Navbar from '../navbar/Navbar';
import Banner from '../banner/Banner';
import Projects from '../projects/Projects';
import CompleteProjects from '../completeProjects/CompleteProjects';
import Join from '../join/Join';
import Member from '../member/Member';
import Performance from '../performance/Performance';
import StickyFooter from '../footer/StickyFooter';

const LandingPage = () => {
  return (
    <>
      <Navbar/>
      <Banner/>
      <Projects/>
      <CompleteProjects/>
      <Join/>
      <Member/>
      <Performance/>
      <StickyFooter/>
    </>
  );
};

export default LandingPage;
