import React from 'react';
import { Button, Link } from '@material-ui/core';
import styles from './stickyFooter.module.css';
import logo from './../../resources/imgs/logo2.png';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const Footer = () => {
    return (
      <div className={styles.rootFooter}>
        <Link name={'footer'}></Link>
        <div className={styles.footerSuscribe}>
          <img src={logo} alt={'logo'} className={styles.logoFooter} />
          <p>Suscribete y enterate de nuestros proyectos</p>
          <input style={{ display: 'block' }} className={styles.footerInput} placeholder={'Email'}/>
          <Button variant={'contained'} className={styles.footerButton} >Suscribirse</Button>
        </div>
        <div className={styles.footerTerms}>
          <Link color={'inherit'} href={'/terms'} underline={'none'}>
            <p>Terminos y condiciones</p>
          </Link>
          <Link color={'inherit'} href={'/privacy'} underline={'none'}>
            <p>Politicas de privacidad</p>
          </Link>
          <p>Comisiones</p>
          <p>Proyectos</p>
          <p>
            <Link color={'inherit'} href={'/about-us'} underline={'none'}>
              Quienes somos
            </Link>
          </p>
          <p>
            <Link color={'inherit'} href={'#footer'} underline={'none'}>
              Contáctanos
            </Link>
          </p>
        </div>
        <div className={styles.footerSocial} style={{ display: 'flex' }}>
          <div>
            <InstagramIcon />
            <p >
              <Link color={'inherit'} target={'_blank'} rel={'noopener'}
                    href={'https://www.instagram.com/farm.energy/'} underline={'none'}>
                @farm.energy
              </Link>
            </p>
          </div>

          <div>
            <FacebookIcon />
            <p >
              <Link color={'inherit'} target={'_blank'} rel={'noopener'}
                    href={'https://www.facebook.com/Farm-Energy-100223699513301/'} underline={'none'}>
                Farm Energy
              </Link>
            </p>
          </div>
          <div>
            <TwitterIcon />
            <p >
              <Link color={'inherit'} target={'_blank'} rel={'noopener'}
                    href={'https://www.twitter.com/farmEnergy'} underline={'none'}>
                /farmEnergy
              </Link>
            </p>
          </div>
          <div>
            <LinkedInIcon />
            <p >
              <Link color={'inherit'} target={'_blank'} rel={'noopener'}
                    href={'https://www.linkedin.com//farmEnergy'} underline={'none'}>
                /farmEnergy
              </Link>
            </p>
          </div>

        </div>
      </div>
    );
};

export default Footer;
