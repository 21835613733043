/* eslint-disable */
import React from 'react';
import Navbar from '../navbar/Navbar';
import StickyFooter from '../footer/StickyFooter';

const TermsContitions = () => (<>
<Navbar />
  <div style={{marginTop: '50px'}}>
    <div style={{padding: '4em 2em'}}>
      <h1 style={{textAlign: 'center'}}>
      ANEXO A - Términos y Condiciones de Uso de la Plataforma- FARM ENERGY</h1>
      <div style={{ padding: '0 200px', textAlign: 'justify'}}>
        <h3>CONSIDERACIONES PREVIAS</h3>
        <p>Este sitio web se encuentra constituido bajo la legislación colombiana y es controlado y operado por FARM ENERGY S.A. E.S.P., sociedad comercial, debidamente constituida y existente de conformidad con las leyes de la República de Colombia.</p>
        <p>FARM ENERGY S.A.S. E.S.P. es una sociedad por acciones simplificada, que en desarrollo de su objeto social se encarga de diseñar, buscar, materializar y explotar proyectos de energía renovable de fuente no convencional en Colombia, bajo la modalidad principalmente de energía solar fotovoltaica.</p>
        <p>FARM ENERGY S.A.S. E.S.P. también está facultada por su objeto social y por la regulación de la ley colombiana, para ser una plataforma digital por medio de la cual se promueve la participación de personas jurídicas y/o naturales en el desarrollo y explotación de proyectos de generación y/o autogeneración de energía renovable de fuente no convencional, facilitando la interacción entre quienes tienen la experiencia en el desarrollo, diseño, materialización y explotación de tales proyectos y quienes tienen la voluntad de participar en el desarrollo y explotación de los mismos, en calidad de inversores.</p>
        <p>los Inversores son personas jurídicas y/o naturales que buscan participar en el desarrollo y explotación de proyectos de generación y/o autogeneración de energía renovable de fuente no convencional.</p>
        <h3>1.	GENERALIDADES FRENTE AL SITIO WEB Y LA PLATAFORMA</h3>
        <p>FARM ENERGY gestiona el presente sitio web y las plataformas asociadas a ella. En todo el sitio web, los términos "nosotros", "nos" y "nuestro" se refieren en lo sucesivo a FARM ENERGY S.A. E.S.P.. FARM ENERGY ofrece este sitio web y/o plataforma, incluida toda la información, las herramientas y los servicios que se ponen a disposición suya, siempre y cuando se acepten la totalidad de los términos, condiciones, políticas y avisos contemplados aquí.</p>
        <p>Al visitar nuestro sitio y/o realizar algún movimiento transaccional a través de nuestra plataforma, usted interactúa con nuestro "Servicio" y reconoce como vinculantes los siguientes términos y condiciones (denominados en lo sucesivo "Términos del servicio", "Términos", “Términos y Condiciones de Uso de la Plataforma”), incluidos aquellos términos que se mencionan en el CONTRATO MARCO DE COLABORACIÓN – FARM ENERGY S.A.S E.S.P., y en el COMPROBANTE DE SUSCRIPCIÓN Y CONDICIONES ESPECÍFICAS DEL PROYECTO y/o los que estén disponibles por medio de hipervínculo en nuestro sitio web.</p>
        <p>El ingreso y/o uso de este sitio web atribuye a quien accede la condición de usuario, aceptando el usuario, desde ese mismo momento, plenamente y sin reserva alguna, sujetarse a los presentes términos y condiciones de uso, a las disposiciones particulares que, en su caso, complementen, modifiquen o sustituyan estos términos y condiciones de uso, cuando así suceda, en relación con todos los contenidos y servicios de esta página web y de la plataforma, y a todas las leyes y reglamentos aplicables que hagan parte de la legislación colombiana.</p>
        <p>Estos Términos del servicio se aplican a todos los usuarios del sitio, incluyendo de manera enunciativa mas no limitativa a los usuarios que son navegadores, inversores, proveedores, clientes, usuarios finales, comerciantes y/o cualquier otro que aporten contenido a la plataforma.</p>
        <p>Lea estos Términos del servicio detenidamente antes de acceder o utilizar nuestra página web y nuestras plataformas. Al acceder o utilizar cualquier parte del sitio, usted acepta estos Términos del servicio. Si no acepta la totalidad de los términos y condiciones de Uso de la Plataforma, no podrá acceder al sitio web ni utilizar ningún servicio. Si estos Términos del servicio se considerasen una oferta, la aceptación se limita expresamente a los presentes Términos del servicio.</p>
        <p>Las nuevas funciones o herramientas que se agreguen a la plataforma en corto, mediano y/o largo plazo, también estarán sujetas a los Términos y condiciones de Uso de la Plataforma. Puede revisar la versión más reciente de los Términos del servicio en cualquier momento en esta página. Aunque advertimos que nos reservamos el derecho de actualizar, cambiar o reemplazar cualquier parte de los presentes Términos del servicio mediante la publicación de actualizaciones o cambios en nuestra página web. Es su responsabilidad revisar esta página periódicamente para ver los cambios a los que sea sometidos los presentes términos y condiciones de uso. El uso del sitio web y de la plataforma, o el acceso a ella de forma continua, después de la publicación de cualquier cambio, constituye la aceptación de dichos ajustes.</p>
        <p>FARM ENERGY S.A. E.S.P. no garantiza que los materiales contenidos en este sitio web o que otros enlaces o vínculos hechos desde este sitio web sean apropiados o acordes a las normas de cualquier otro país diferente, o se encuentren disponibles para su uso por fuera de otros países.</p>
        <h3>2.	ACEPTACIÓN DE LOS TÉRMINOS DEL SITIO WEB Y  LA PLATAFORMA </h3>
        <p>Al aceptar los presentes Términos del servicio, usted declara que cumple con la mayoría de edad en la República de Colombia y que tiene plena capacidad para actuar.</p>
        <p>El Usuario no podrá distribuir, modificar, copiar, transmitir, mostrar, utilizar, reproducir, publicar, otorgar licencias, crear obras derivadas, ceder, transferir, vender o utilizar de cualquier otra forma el Contenido o el Software del Sitio sin la autorización previa, expresa y por escrito de FARM ENERGY S.A. E.S.P.</p>
        <p>No puede utilizar nuestros servicios para ningún fin ilegal o no autorizado, ni puede, al hacer uso de nuestra plataforma, infringir las leyes de la jurisdicción Colombiana ni las del ámbito internacional (incluyendo de manera enunciativa más no limitativa, las leyes de derechos de autor y de propiedad intelectual).</p>
        <p>El incumplimiento o violación de cualquiera de los Términos dará como resultado la rescisión inmediata de sus Servicios.</p>
        <p>En caso que el usuario no esté de acuerdo total o parcialmente con estos términos y condiciones de uso, se deberá abstener de usar esta página web.</p>
        <h3>3. CONDICIONES GENERALES</h3>
        <p>Nos reservamos el derecho de rechazar el uso de nuestra plataforma y de nuestra intermediación a cualquier persona o usuario, por cualquier motivo y en cualquier momento. Usted acepta no reproducir, duplicar, copiar, vender, revender ni aprovechar ninguna parte de la plataforma o sitio web, sin nuestro permiso expreso por escrito. Los encabezados utilizados en este acuerdo se incluyen solo para facilitar la lectura y no limitarán ni afectarán los presentes Términos.</p>
        <h3>4.	CONDICIONES PARTICULARES</h3>
        <p>El ingreso y/o uso de este sitio web por parte del usuario deberá hacerse teniendo en cuenta, entre otras, las siguientes condiciones particulares, las cuales se entienden comprendidas y aceptadas por el usuario, al ingresar y/o usar este sitio web y la plataforma, y mientras éstas se encuentren en este sitio web, FARM ENERGY S.A. E.S.P. no será responsable por su cumplimiento y el usuario mantendrá indemne a FARM ENERGY S.A. E.S.P. por todo concepto: </p>
        <ul>
          <li>El usuario está en la obligación de acatar estos términos y condiciones de uso, el aviso de privacidad y el manual de políticas de tratamiento de información de FARM ENERGY S.A. E.S.P., así como cualquier otra condición establecida en este sitio web. </li>
          <li>El usuario está en la obligación de emplear la debida precaución y diligencia cuando acceda, use y consulte este sitio web. </li>
          <li>El usuario debe dar cumplimiento a todas las leyes, normas y reglamentos aplicables al acceso, uso y consulta de sitios en internet.</li>
          <li>El usuario es el único responsable por su conducta y por el contenido de textos, gráficos, fotos, videos o cualquier otro tipo de información de la cual haga uso o incluya en este sitio web. </li>
          <li>El usuario no puede infringir cualquier ley o regulación aplicable, por tanto, al usuario no le está permitido el uso de este sitio web con fines ilícitos o lesivos contra este sitio web o cualquier tercero, o que, de cualquier forma, puedan causar perjuicio o impedir el normal funcionamiento de este sitio web. </li>
          <li>El usuario no puede realizar actuaciones que puedan generar en este sitio web o a través del mismo y por cualquier medio, entre otros pero sin limitarse a, errores, daños, interrupciones o suspensión del funcionamiento del sitio web ni a los sistemas o plataformas de FARM ENERGY S.A. E.S.P. o a terceros, ni que puedan atentar contra la información contenida en el sitio web, los computadores o la propiedad de terceros. </li>
          <li>Al usuario le está prohibido usar este sitio web y las plataformas de de FARM ENERGY S.A. E.S.P. para implantar, enviar, distribuir o transmitir cualquier virus o programa de computador dañino o potencialmente dañino, así como cualquier programa que tenga como propósito dañar la operación, el acceso, uso o contenido de esta página web, o impedir o limitar el acceso o uso de la misma por parte de otros usuarios. </li>
          <li>Al usuario le está prohibido incluir, publicar, exponer, cargar, distribuir o diseminar cualquier tema, nombre, material o información inapropiado, infractor, inmoral, ilegal, obsceno, blasfemo, difamatorio, abusivo, amenazante, vulgar, racista, sexista, escandaloso, provocador, pornográfico, irreverente, y ofensivo para FARM ENERGY S.A. E.S.P., o terceros, o cualquier otro usuario, o que atente contra derechos y libertades fundamentales, o que constituya un delito o conducta criminal bajo las leyes vigentes.  </li>
          <li>Al usuario le está prohibido crear una identidad falsa con el propósito de confundir a otros y/o suplantar a otro usuario o a cualquier tercero. </li>
          <li>Los contenidos de este sitio web están protegidos por las leyes que regulan los derechos de autor y derechos marcarios y, son de propiedad exclusiva de FARM ENERGY S.A. E.S.P. Salvo manifestación expresa en contrario, el usuario puede acceder a los contenidos de este sitio web sólo para su uso personal, es decir, el usuario puede descargar copias de los materiales incluidos sólo para su uso personal y no comercial ni de propaganda política, por tanto, el usuario no podrá cambiar o suprimir ninguna referencia al autor, las marcas o lemas comerciales registrados o leyendas de uso de acuerdo con los derechos de autor. </li>
          <li>El usuario no puede modificar, copiar, publicar, mostrar, transmitir, adaptar o en ninguna forma explotar el contenido de este sitio web o de las plataformas de de FARM ENERGY S.A. E.S.P. </li>
          <li>El usuario no puede infringir, destruir, modificar o utilizar información de terceros sin la debida autorización del titular de la información. </li>
          <li>Cuando el usuario descargue material protegido por derechos de autor, no adquiere ningún derecho sobre esos materiales. </li>
          <li>Al usuario no le está permitido utilizar el nombre de FARM ENERGY S.A. E.S.P. o utilizar las plataformas tecnológicas suministradas por ésta para distribuir mensajes comerciales o correos no solicitados. </li>
          <li>Queda prohibida toda modificación, adaptación, traducción, ingeniería inversa, descompilación o desmontaje de cualquier parte del Sitio web o de las plataformas de de FARM ENERGY S.A. E.S.P.</li>
          <li>El usuario de esta página web y de la plataforma responderá por cualquier perjuicio o daño causado a FARM ENERGY S.A. E.S.P. por el uso indebido de este sitio web y por cualquier infracción e incumplimiento de estos términos y condiciones de uso. </li>
        </ul>
        <p>FARM ENERGY S.A. E.S.P. estará dispuesta a suministrar la información que le sea requerida con el fin de colaborar con las autoridades competentes, frente a cualquier incumplimiento o infracción a lo dispuesto en estos términos y condiciones de uso.</p>
        <h3>5.	PROTECCIÓN DE INFORMACIÓN PERSONAL</h3>
        <p>Mediante el ingreso al Sitio web, a las plataformas y/o su registro, el usuario autoriza a FARM ENERGY S.A. E.S.P. a usar y tratar los datos personales por él ingresados al Sitio, para los fines establecidos en el Sitio y en especial, con el fin de permitirle crear una base de datos de los usuarios registrados, enviar información comercial, publicitaria, técnica de FARM ENERGY S.A. E.S.P., y/o sus productos y/o sus servicios a los Usuarios, construir estrategias comerciales con los usuarios registrados y la información por ellos reportada, uso de la información en procesos comerciales operativos de FARM ENERGY S.A. E.S.P., la programación y envío de obsequios, postales y cualquier material publicitario así como para cualquier otro fin comercial que permita desarrollar relación comercial con los usuarios para los servicios que brinda FARM ENERGY S.A. E.S.P.. </p>
        <p>La información de los usuarios y demás contenidos publicados en el Sitio, que tengan la calidad de datos personales de acuerdo con lo establecido en la Ley 1581 de 2012, serán tratados como confidenciales y protegidos de acuerdo a la Ley 1581 de 2012 sus demás normas concordantes y complementarias y la política de protección de Datos Personales de FARM ENERGY S.A. E.S.P. </p>
        <p>FARM ENERGY S.A. E.S.P. tomará medidas razonables lógicas, administrativas y físicas para proteger del acceso no autorizado de terceros a la información personal obtenida a través del Sitio, así como para evitar la adulteración, pérdida, consulta o uso no autorizado o fraudulento por parte de terceros de la información personal. Sin embargo, FARM ENERGY S.A. E.S.P. no se hace responsables por el ingreso no autorizado de terceros al Sitio y el acceso a la información personal de los usuarios y visitantes, vulnerando las medidas a través de internet. En estos eventos FARM ENERGY S.A. E.S.P. gestionará el incidente de seguridad de conformidad con los lineamientos establecidos por la ley aplicable. </p>
        <p>El usuario autoriza a FARM ENERGY S.A. E.S.P. a dar a conocer su información personal a las autoridades competentes en cumplimiento de la ley o de orden administrativa, y a conservar dicha información para las finalidades acá establecidas, pudiendo conservarla hasta que la finalidad para la cual hayan sido obtenidos tales datos se haya cumplido. </p>
        <p>El usuario podrá cada que lo desee ingresar a su perfil para hacer los cambios necesarios en sus datos personales cuando estos deban ser actualizados o cuando desee eliminarlos. El usuario podrá ejercer derechos reconocidos por el artículo 8 de la Ley 1581 de 2012, respecto de los datos personales que haya ingresado a través del Sitio. </p>
        <p>En caso de alguna inquietud, comentario o consulta relacionada con el manejo de datos personales del usuario, podrá consultarla en el siguiente correo electrónico: soporte@farmenergy.app o en la política de privacidad.</p>
        <h3>6. PROTECCIÓN DE LA INFORMACIÓN DEL USUARIO</h3>
        <p>FARM ENERGY S.A. E.S.P. utiliza mecanismos físicos y electrónicos, además de procedimientos y prácticas, que buscan salvaguardar la información de los Usuarios. Dentro de estos mecanismos, FARM ENERGY S.A. E.S.P. utiliza tecnología de encriptación de datos u otras tecnologías para proteger la información que el Usuario suministra en el Sitio y que es guardada en las bases de datos de FARM ENERGY S.A. E.S.P. </p>
        <p>FARM ENERGY S.A. E.S.P. utiliza tecnologías y servicios que cuentan con sistemas de seguridad para proteger los servidores de ataques y/o hackeos de terceros. No obstante, FARM ENERGY S.A. E.S.P. no puede garantizar que el Sitio no sea objeto de hackeos o ciberataques y, por ello, tampoco la información referente al ingreso al Sitio, a la admisión y acceso como Usuario, al igual que la información suministrada por los Usuarios. Lo anteriormente mencionado corre por cuenta y riesgo del Usuario al aceptar estos términos y condiciones de uso y sus Anexos o Acuerdos Específicos que se hagan a través de la plataforma.</p>
        <p>Por lo anterior, FARM ENERGY S.A. E.S.P. no se responsabiliza por cualquier daño, perjuicio o pérdida al usuario causados por fallas en el sistema, en el servidor o en Internet.</p>
        <p>FARM ENERGY S.A. E.S.P. tampoco será responsable por cualquier virus que pudiera infectar el equipo del usuario como consecuencia del acceso, uso o examen de su sitio web o a raíz de cualquier transferencia de datos, archivos, imágenes, textos, o audios contenidos en el mismo. </p>
        <p>Los Usuarios no podrán imputarle responsabilidad alguna ni exigir pago por lucro cesante, en virtud de perjuicios resultantes de dificultades técnicas o fallas en los sistemas o en Internet. FARM ENERGY S.A. E.S.P. no garantiza el acceso y uso continuado o ininterrumpido de su plataforma tecnológica. El sistema puede eventualmente no estar disponible debido a dificultades técnicas o fallas de Internet, o por cualquier otra circunstancia ajena a FARM ENERGY S.A. E.S.P.; en tales casos se procurará restablecerlo con la mayor celeridad posible sin que por ello pueda imputársele algún tipo de responsabilidad. </p>
        <p>FARM ENERGY S.A. E.S.P. no será responsable por algún error u omisión de información en su sitio web o en cualquiera de sus plataformas. </p>
        <p>Asimismo, no está permitida ninguna acción o uso de dispositivo, software, u otro medio tendiente a interferir tanto en las actividades y operación de la Plataforma o del sitio web. Cualquier intromisión, tentativa o actividad violatoria o contraria a las leyes sobre derecho de propiedad intelectual y/o a las prohibiciones estipuladas en estos términos y condiciones, harán responsable al infractor de las acciones legales pertinentes, así como lo hará responsable de indemnizar los daños y perjuicios ocasionados.</p>
        <h3>7.	ALMACENAMIENTO DE LA INFORMACIÓN</h3>
        <p>FARM ENERGY S.A. E.S.P. guarda la información obtenida a través del Sitio y a través de otros mecanismos de recolección de datos de sus Usuarios en sus bases de datos, con el objetivo de proveer un adecuado servicio a los Usuarios Registrados y asegurar la integridad de los datos y de prevenir los fraudes de terceros.</p>
        <p>En todo caso bajo ninguna circunstancia FARM ENERGY S.A. E.S.P. o los terceros que éste contrate almacenarán claves de acceso o nombres de usuario de otros sitios web que el usuario ingrese en el sitio web o en la Plataforma de FARM ENERGY S.A. E.S.P. </p>
        <p>Sin perjuicio de lo señalado en los puntos anteriores, el Usuario comprende que las medidas de seguridad en Internet no son inquebrantables y asume los riesgos que se deriven de revelaciones fuera del control de FARM ENERGY S.A. E.S.P. que se generen como producto de ataques informáticos que no pudieren ser resistidos con los sistemas y conocimientos tecnológicos comúnmente conocidos.</p>
        <h3>8.	VERACIDAD DE LA INFORMACIÓN DEL USUARIO</h3>
        <p>El Usuario se obliga a que toda la información que proporcione a FARM ENERGY S.A. E.S.P., ya sea a través del Sitio (en formularios de Registro de Usuario u otro medio suministrado por FARM ENERGY S.A. E.S.P.), y/o de otros medios que FARM ENERGY S.A. E.S.P. haya definido con el Usuario (email, correo, en las oficinas de FARM ENERGY S.A. E.S.P., entre otros), debe ser veraz, fidedigna y comprobable. Asimismo, el Usuario es responsable exclusivo de mantener su información personal siempre actualizada.</p>
        <p>El Usuario, a través de la aceptación de estos términos y condiciones de uso, garantiza la autenticidad de todos aquellos datos proporcionados a FARM ENERGY S.A. E.S.P. y de aquellos datos que vaya actualizando en el tiempo, siendo el Usuario el único responsable por los daños y perjuicios generados por cualquier inexactitud en la información que pudiera recaer sobre sí mismo, sobre FARM ENERGY S.A. E.S.P. y/o sobre terceros. </p>
        <p>FARM ENERGY S.A. E.S.P. no se hace responsable frente a terceros por las faltas en que incurra el Usuario respecto de este numeral.</p>
        <p>El Usuario comprende que no podrá crear cuentas o utilizar el Sitio web o las plataformas, proporcionando datos falsos, so pena de incurrir en el delito de falsedad en documento privado, consagrado en el artículo 289 del Código Penal.</p>
        <p>FARM ENERGY S.A. E.S.P. se reserva el derecho de verificar la información proporcionada por el Usuario. En caso de comprobar que la información o una parte de ella no es veraz o no corresponde al Usuario que la proporcionó, FARM ENERGY S.A. E.S.P. podrá desactivar la cuenta y el registro del Usuario, pudiendo poner término a la relación contractual que los vincula, y negar el acceso al Usuario a futuro. Sin perjuicio del derecho que tiene FARM ENERGY S.A. E.S.P. de iniciar las acciones legales que estime convenientes contra el Usuario que haya proporcionado información falsa.</p>
        <h3>9.  EXCLUSIÓN Y LIMITACIONES DE RESPONSABILIDAD DE CONTENIDOS Y TÉCNICA:</h3>
        <p>Al ingresar y/o usar este sitio web y las plataformas de FARM ENERGY S.A. E.S.P., el usuario acepta en forma expresa que su uso se realiza bajo su exclusiva responsabilidad y riesgo. Al cumplir con las normas antes indicadas, significa que el usuario acepta expresamente que el uso del sitio se realiza bajo su exclusivo riesgo. Por lo tanto, FARM ENERGY S.A. E.S.P., no será responsable de eventuales daños o perjuicios, directos o indirectos, presentes o futuros, que el Usuario o terceros pudiesen sufrir, con motivo o con ocasión de la ejecución de cualquier instrucción o de la información contenida en este sitio que ocasione errores, omisiones, interrupciones, eliminación o corrupción de archivos, virus, demoras en la operación o transmisión, o cualquier otro tipo de error en el funcionamiento; liberando desde ya a FARM ENERGY S.A. E.S.P. de cualquier responsabilidad administrativa, civil o penal al respecto.</p>
        <p>Se advierte al usuario sobre la necesidad de validar la información antes de hacer uso de la misma. El material y los componentes técnicos de este sitio web pueden incluir errores y presentar fallas técnicas en cualquier momento, así como estar desactualizada o incompleta, y por ende, el uso que haga el usuario será bajo su entero riesgo y responsabilidad. </p>
        <p>Bajo ninguna circunstancia FARM ENERGY S.A. E.S.P. será responsable por cualquier pérdida, daño, reclamo o responsabilidad de cualquier tipo que resulte del uso del sitio web o su contenido. Esto incluye cualquier daño especial, daño emergente, directo, indirecto, imprevisto, incidental o consecuencial, lucro cesante, o de cualquier otro tipo (incluyendo, sin limitación, honorarios de abogados) en algún modo debido a, resultado de, o que surja en conexión con el uso o inhabilidad para usar el sitio web o el contenido, errores, omisiones, interrupciones, eliminación o corrupción de archivos, virus, demoras en la operación o transmisión, o cualquier otro tipo de error en su funcionamiento. </p>
        <p>FARM ENERGY S.A. E.S.P. no responderá por la exactitud, veracidad, oportunidad e integridad de la información contenida en este sitio web, ni de la información contenida en los documentos que establezcan enlaces a esta página web, ni por eventuales problemas de carácter técnico, inexactitudes, omisiones o errores de transcripción que pudieran afectar el contenido de este sitio web o de los enlaces contenidos en el mismo. </p>
        <p>FARM ENERGY S.A. E.S.P. no asume responsabilidad alguna ni derivada de cualquier daño o perjuicio, incluyendo, más no limitado a, la pérdida de información o utilidades, existencia de virus, resultados del uso o la incapacidad para usar el material en este sitio, oportunidades de negocios perdidas, o cualquier otro daño, o por cualquier reclamo de terceros.</p>
        <p>FARM ENERGY S.A. E.S.P. no asume responsabilidad alguna por problemas o por la imposibilidad de utilización de este sitio o de alguna de los sitios web que lo conforman, incluyendo pero sin limitarse a eventos tales como problemas en el servidor o en la conexión, interrupciones en su comunicación, problemas técnicos. </p>
        <p>FARM ENERGY S.A. E.S.P. no se hace responsable, en ningún caso, de los daños y perjuicios de cualquier naturaleza que pudieran traer causa de la falta de disponibilidad, mantenimiento y efectivo funcionamiento de este sitio web y/o de sus servicios o contenidos, la falta de utilidad, adecuación o validez del sitio web y/o de sus servicios o contenidos para satisfacer necesidades, actividades o resultados concretos o expectativas del usuario, la existencia de virus, programas maliciosos o lesivos en los contenidos, la recepción, obtención, almacenamiento, difusión o transmisión, por parte del usuario, de los contenidos, así como del uso ilícito, negligente, fraudulento, contrario a los presentes términos y condiciones de este sitio web, sus servicios o contenidos, por parte del usuario. </p>
        <p>FARM ENERGY S.A. E.S.P. no garantiza la privacidad y seguridad de la utilización de este sitio web y, en particular, no garantiza que terceros no autorizados de manera fraudulenta puedan tener conocimiento de la clase, condiciones, características y circunstancias del uso que el usuario hace de esta página web. Se excluye toda responsabilidad por los daños y perjuicios de toda naturaleza que pudieran deberse al conocimiento que puedan tener terceros no autorizados de la clase, condiciones, características y circunstancias del uso que el usuario hace de esta página web y de sus servicios. En caso de que el uso del material de este sitio web arroje como resultado la necesidad de dar servicio, reparar o corregir equipo o información, el usuario deberá asumir cualquier costo derivado de ello. </p>
        <p>La exclusión y limitaciones de responsabilidad precedentes se aplicará en toda acción legal, aún cuando FARM ENERGY S.A. E.S.P. haya sido informada o tuviera previamente conocimiento de dichos daños.</p>
        <h3>10.	PROPIEDAD INTELECTUAL E INDUSTRIAL</h3>
        <p>Todos los contenidos del sitio web (incluyendo, sin carácter limitativo, bases de datos, imágenes y fotografías, material informativo, gráfico, publicitario, patentes, modelos de utilidad e industriales, dibujos, archivos de texto, audio, bases de datos, video y software) son propiedad de FARM ENERGY S.A. E.S.P., o de los proveedores de contenidos, habiendo sido, en este último caso, objeto de licencia o cesión, por parte de los mismos, y están protegidos por las normas nacionales o internacionales de propiedad intelectual e industrial. </p>
        <p>La compilación, entendiéndose como tal la recopilación, diseño, ordenación y montaje, de todo el contenido de este sitio web es propiedad exclusiva de FARM ENERGY S.A. E.S.P. y se encuentra protegida por las normas nacionales e internacionales de propiedad industrial e intelectual. </p>
        <p>Todo el software utilizado en el diseño de las pantallas, navegación y uso y desarrollo de este sitio web es propiedad de FARM ENERGY S.A. E.S.P. o de sus proveedores de software y se encuentra protegido por las leyes nacionales e internacionales de propiedad industrial e intelectual. </p>
        <p>Las marcas, diseños, signos distintivos y/o logos que aparecen en este sitio web son titularidad de FARM ENERGY S.A. E.S.P. y están debidamente registrados o en proceso de registro. Todos los textos, dibujos gráficos, videos o soportes de audio son propiedad de FARM ENERGY S.A. E.S.P., o de sus proveedores de contenidos, no pudiendo ser objeto de modificación, copia, alteración, transformación, reproducción, adaptación o traducción por parte suya o de terceros sin la expresa autorización por parte de los titulares de dichos contenidos. </p>
        <p>La puesta a disposición del contenido de este sitio para uso de las bases de datos, dibujos, gráficos, imágenes y fotografías, archivos de texto, audio, video y software propiedad de FARM ENERGY S.A. E.S.P. o de sus proveedores, no implica, en ningún caso, la cesión de su titularidad o la concesión de un derecho de explotación en favor del usuario, distinto del derecho de uso que comporta la utilización legítima y acorde con la naturaleza de esta página web. </p>
        <p>FARM ENERGY S.A. E.S.P. no concede ninguna licencia o autorización de uso de ninguna clase sobre sus derechos de propiedad industrial e intelectual o sobre cualquier otra propiedad o derecho relacionado con este sitio web, sus servicios o su contenido, por tanto, se encuentra prohibido cualquier uso de los contenidos de este sitio web o de los servicios que contenga y, en general, de todos los derechos mencionados de manera precedente, que se realice sin la autorización de FARM ENERGY S.A. E.S.P., incluida su explotación, reproducción, difusión, transformación, distribución, transmisión por cualquier medio, posterior publicación, exhibición, comunicación pública o representación total o parcial las cuales, de producirse, constituirán infracción de los derechos de propiedad intelectual de FARM ENERGY S.A. E.S.P., sancionados por la legislación vigente</p>
        <p>FARM ENERGY S.A. E.S.P., a su sola discreción y sin previo aviso, se reserva el derecho de suspender la cuenta o el acceso de cualquier usuario que pueda presentar contenido que presuntamente infrinja derechos de propiedad intelectual o industrial u cualquier otro derecho, ley, reglamento o norma, o estos términos y condiciones de uso, o cuando no haga uso apropiado de este sitio web o de las plataformas de FARM ENERGY S.A. E.S.P.</p>
        <h3>11.	EXACTITUD DE LA FACTURACIÓN Y DE LA INFORMACIÓN DE LA CUENTA</h3>
        <p>Nos reservamos el derecho de rechazar cualquier movimiento o transacción que se realice en nuestra plataforma o sitio web. Podemos, a nuestro exclusivo criterio, limitar o cancelar los movimiento o transacciones hechos por persona, por hogar o por empresa. Estas restricciones pueden incluir movimientos realizados con la misma cuenta de cliente, la misma tarjeta de crédito o pedidos que usen la misma dirección de facturación. En el caso de que realicemos un cambio o cancelemos un movimiento, intentaremos notificarle vía correo electrónico o la dirección de facturación / número de teléfono proporcionados en el momento en que se realizó el registro. Nos reservamos el derecho de limitar o prohibir los movimientos que, a nuestra entera discreción, parezcan haber sido realizados con fines fraudulentos o en violación de las políticas de prevención de lavado de activos.</p>
        <p>Usted acepta suministrar información completa y precisa de sus datos financieros, para todos los movimientos realizados en nuestra plataforma. Usted acepta actualizar rápidamente su cuenta y demás información, entre ellas, su dirección de correo electrónico, los números de tarjeta de crédito y las fechas de vencimiento, para que podamos completar sus transacciones y contactarlo según sea necesario.</p>
        <h3>12.	USOS PROHIBIDOS</h3>
        <p>Además de las prohibiciones establecidas en el CONTRATO MARCO DE COLABORACIÓN – FARM ENERGY S.A.S E.S.P., se le prohíbe utilizar el sitio, la plataforma o su contenido (a) para cualquier propósito ilegal o fraudulento que desconozca o viole las políticas de prevención de lavado de activos ; (b) para solicitar a otros que realicen o participen en cualquier acto ilegal; (c) para infringir cualquier reglamento, norma, ley u ordenanza local, internacional, departamental o nacional; (d) para infringir o violar nuestros derechos de propiedad intelectual o los derechos de propiedad intelectual de otros; (e) acosar, abusar, insultar, dañar, difamar, calumniar, denigrar, intimidar o discriminar por motivos de género, orientación sexual, religión, etnia, raza, edad, nacionalidad o discapacidad; (f) enviar información falsa o engañosa; (g) cargar o transmitir virus o cualquier otro tipo de código dañino que afecte o pueda afectar a la funcionalidad o el funcionamiento de nuestra plataforma o de cualquier sitio web relacionado, de otros sitios web o de Internet; (h) recopilar o rastrear la información personal de otros; (i) enviar correo no deseado, phishing, pretexto, spider, rastrear o extraer información de terceros; (j) para cualquier propósito obsceno o inmoral; o (k) para interferir o eludir las funciones de seguridad de la plataforma o de cualquier sitio web relacionado, o de otros sitios web o de Internet. Nos reservamos el derecho de dar por terminado su uso de la plataforma o de cualquier sitio web relacionado por infringir cualquiera de los usos prohibidos.</p>
        <h3>13.	CONTENIDO DE LOS USUARIOS DEL SITIO WEB O DE OTROS TERCEROS </h3>
        <p>Este sitio web puede permitir al usuario enviar y/o cargar y/o publicar una gran variedad de contenido, que incluye pero no se limita a: declaraciones escritas, ideas, opiniones, sugerencias y comentarios, imágenes, fotos, contenido de audio y video. </p>
        <p>Si el usuario decide hacerlo, declara entender, estar de acuerdo, y consentir en hacerse responsable de cualquier incumplimiento de los requisitos legales sobre el contenido que está enviando y/o cargando y/o publicando. En particular, se compromete a abstenerse de enviar y/o cargar y/o publicar cualquier contenido que sea ilegal de cualquier forma, obtenido o compartido en desacato de cualquier ley o requisitos, o que sea pornográfico, ofensivo, vulgar, o que tenga la intención o potencialidad de perjudicar o dañar cualquier parte de este sitio web, sus usuarios o los equipos informáticos de FARM ENERGY S.A. E.S.P. o sus proveedores. </p>
        <p>También confirma, declara y garantiza que posee o tiene las licencias, derechos, consentimientos y permisos necesarios para usar y autorizar a FARM ENERGY S.A. E.S.P. para utilizar toda propiedad intelectual, incluyendo pero no limitado a: patentes, marcas, marcas de servicios, imagen comercial, secretos comerciales, derechos de autor, imágenes, nombres, u otros derechos de propiedad intelectual en y de cualquier contenido del usuario, para permitir el uso de dicho contenido en la forma prevista por este sitio web y en estos términos y condiciones. </p>
        <p>FARM ENERGY S.A. E.S.P. se reserva el derecho de remover cualquier contenido presentado en esta página web en cualquier momento, por cualquier razón y sin previo aviso. </p>
        <p>Teniendo en cuenta la posibilidad del usuario de enviar y/o cargar y/o publicar en este sitio web declaraciones escritas, ideas, opiniones, sugerencias y comentarios, imágenes, fotos, contenido de audio, video, o cualquier otro tipo de contenido o información de titularidad del usuario o bien por éste de titularidad de un tercero, se deja expresamente establecido que al incluirlo en este sitio web se considera que el usuario autoriza a FARM ENERGY S.A. E.S.P. para que ese contenido aparezca y quede en este sitio web sin derecho a contraprestación alguna por parte de FARM ENERGY S.A. E.S.P.</p>
        <p>En relación con cualquier tipo de contenido enviado y/o cargado y/o publicado a este sitio web de propiedad del usuario o de un tercero que lo hubiere facultado, se considera que el usuario es titular de los derechos de autor sobre dicho contenido y que al haber sido incluido en este sitio web, el usuario otorga licencia gratuita, incondicional y no exclusiva de uso de tal contenido a favor de FARM ENERGY S.A. E.S.P., sin derecho a percibir contraprestación, remuneración, retribución o reclamo posterior alguno, cualquiera sea su tipo o índole. </p>
        <p>FARM ENERGY S.A. E.S.P. podrá a su vez hacer cualquier tipo de uso de dicho contenido, en virtud de la licencia de uso así concedida. En tal sentido, el usuario reconoce que FARM ENERGY S.A. E.S.P. podrá realizar la reproducción y sincronización del contenido publicado, en cualquier formato, conocido o por conocerse. Asimismo, la comunicación pública o distribución de dicho contenido se podrá realizar a través de cualquier medio existente o por desarrollarse. </p>
        <p>Consecuentemente, FARM ENERGY S.A. E.S.P. queda en plena libertad de reproducir, distribuir, fijar, comunicar públicamente, transformar, comercializar y realizar cualquier negociación plena y sin ninguna limitación en relación con los derechos patrimoniales sobre cualquier contenido enviado y/o cargado y/o publicado por el usuario de este sitio web. Por ende, el usuario remitente de tal contenido y/o información no podrá reclamar indemnización o participación alguna en razón del uso que FARM ENERGY S.A. E.S.P. de a éste. </p>
        <p>Aunque FARM ENERGY S.A. E.S.P. puede monitorear o revisar debates, chats, anuncios, transmisiones, carteleras de anuncios y similares en este sitio web, no está bajo ninguna obligación de hacerlo y no asume ninguna responsabilidad derivada del contenido de dichos materiales, ni por cualquier error, difamación, injuria, calumnia, omisión, falsedad, obscenidad, pornografía, blasfemia, peligro o inexactitud contenida en cualquier información dentro de esos lugares en este sitio web. </p>
        <p>Al usuario no le está permitido poner en, o publicar en, o transmitir a, o desde este sitio web cualquier material ilegal, amenazador, injurioso, calumnioso, difamatorio, obsceno, escandaloso, explosivo, pornográfico o profano, o cualquier otro material que pudiera constituir o alentar una conducta que pueda ser considerada una ofensa criminal, o dar lugar a cualquier responsabilidad civil, o penal, o de otro modo quebrantar cualquier ley. </p>
        <p>FARM ENERGY S.A. E.S.P. se reserva el derecho de retirar, suprimir o modificar cualquier parte o todo el contenido y/o información enviada y/o cargada y/o publicada por el usuario si a su juicio tal contenido y/o información es inapropiada, ilegal, inconveniente o contraria a estos términos y condiciones. </p>
        <p>FARM ENERGY S.A. E.S.P. colaborará plenamente con cualquier autoridad competente que le solicite u ordene a revelar la identidad de cualquier persona que envíe y/o cargue y/o publique tal contenido y/o información. </p>
        <h3>14.	ENLACES O VÍNCULOS </h3>
        <p>Es posible que este u otros sitios web de FARM ENERGY S.A. E.S.P. contengan enlaces a otros sitios de los que FARM ENERGY S.A. E.S.P. no sea titular. Estos enlaces están disponibles para la comodidad del usuario y tienen como única finalidad permitir el acceso a estos sitios de terceros. 
FARM ENERGY S.A. E.S.P. no garantiza, aprueba, supervisa ni realiza declaración alguna acerca de la esencia, calidad, funcionalidad, precisión, aptitud para un propósito determinado, o cualquier contenido de los sitios de terceros. </p>
<p>FARM ENERGY S.A. E.S.P. no tiene ninguna forma de controlar el contenido de esos sitios web. Es responsabilidad del usuario tomar las medidas de seguridad y de prevención necesarias para utilizar los servicios e información que le brinde cada sitio web, así como revisar y cumplir las condiciones y términos que cada uno de ellos exponga. De acuerdo con lo anterior, el usuario reconoce y acepta que FARM ENERGY S.A. E.S.P. no será responsable, directa o indirectamente, por cualquier daño o perjuicio causado o que se presuma que sea causado por tales contenidos, productos o servicios disponibles en dichos sitios web, o por la utilización o confianza depositada por el usuario en tales contenidos, productos o servicios. </p>
        <p>Estos términos y condiciones sólo son aplicables para el uso de esta página web y no para el uso de sitios web que no estén relacionados con FARM ENERGY S.A. E.S.P. o de cualquier otra compañía u organización, así la página web incluya enlaces o vínculos a los cuales conecte. </p>
        <p>FARM ENERGY S.A. E.S.P. no es responsable de la disponibilidad de ninguno de los sitios a los que esta página web pueda conectar. </p>
        <p>FARM ENERGY S.A. E.S.P. no asume responsabilidad por los contenidos, publicidad, productos u otros materiales obtenidos a través de ningún otro sitio web. En consecuencia, el acceso a tales enlaces o vínculos a través de este sitio web se hará bajo el entero riesgo y exclusiva responsabilidad del usuario, sin que de manera alguna se comprometa la responsabilidad de FARM ENERGY S.A. E.S.P. </p>
        <p>En cualquier caso, FARM ENERGY S.A. E.S.P. se reserva el derecho de rescindir cualquier permiso concedido y de solicitar la finalización de la conexión con otros sitios web en cualquier momento. </p>
        <h3>15.	COOKIES</h3>
        <p>FARM ENERGY S.A. E.S.P. utiliza cookies para registrar las preferencias de los usuarios, analizar el tráfico de este sitio web, evaluar y mejorar el contenido y funciones de este sitio web y optimizar el diseño y los contenidos del mismo. </p>
        <p>Las cookies son pequeños archivos de texto enviados por un sitio web y que se guardan en el disco duro del computador. La mayoría de los navegadores aceptan cookies automáticamente. El usuario siempre puede configurar su navegador para rechazar todas las cookies de este u otros sitios web que visite. </p>
        <p>Si el usuario opta por no aceptar cookies, igualmente podrá usar este sitio web, sin embargo, es posible que algunas partes de este sitio web no funcionen correctamente o se ejecuten lentamente si elige rechazar las cookies. </p>
        <p>Al usar este sitio web o las plataformas de FARM ENERGY S.A. E.S.P. y no deshabilitar las cookies, el usuario acepta su uso para los propósitos descritos. </p>
        <h3>16.	ACTIVIDADES PROMOCIONALES </h3>
        <p>Todas las actividades promocionales tendientes a impulsar los servicios de FARM ENERGY S.A. E.S.P. que sean publicadas en este sitio web o en las plataformas, se regirán, además, por los términos y condiciones que se publicarán dentro de esta página web respecto de cada actividad siempre que se genere cada una de ellas y, deberán ser revisados y aceptados por cada uno de los usuarios que pretendan participar en la respectiva actividad promocional. Por tanto, como cada actividad promocional que se promueva o realice a través de este sitio web o las plataformas, estará sujeta a los términos y condiciones que para la misma se indiquen, la participación en la misma deberá atenerse a lo que en cada caso se señale.</p>
        <h3>17.	COMUNICACIONES GENERADAS EN EL SITIO</h3>
        <p>FARM ENERGY S.A. E.S.P. enviará notificaciones al Usuario a través de su email y/o mediante mensajería interna del sitio web o de la Plataforma, notificaciones que se encontrarán relacionadas con el uso de los servicios ofrecidos en la Plataforma. </p>
        <p>FARM ENERGY S.A. E.S.P. se reserva el derecho a no enviar todas las comunicaciones y/o notificaciones al Usuario por email. FARM ENERGY S.A. E.S.P. podrá comunicar cierta información únicamente a través de mensajería interna del sitio o de la plataforma, o mediante correo certificado por alguna compañía de encomiendas que tenga dicho servicio.</p>
        <h3>18.	TERMINACIÓN </h3>
        <p>FARM ENERGY S.A. E.S.P. se reserva el derecho de interrumpir, suspender o terminar el uso de este sitio web o de cualquiera de los servicios que lo integran, en cualquier momento y sin previo aviso.</p>
        <h3>19.	 LEY APLICABLE</h3>
        <p>Los presentes Términos y condiciones de uso y cualquier acuerdo por separado por el cual FARM ENERGY S.A. E.S.P. proporcione servicios, se regirán e interpretarán de acuerdo con las leyes de la República de Colombia.</p>
        <p>Cualquier conflicto o controversia surgida en relación con y/o a partir de los términos y condiciones de uso, será inicialmente sometida al mecanismo de conciliación en la República de Colombia, y en caso de declararse fallida, dicha controversia o conflicto será sometida al conocimiento de la justicia ordinaria colombiana.</p>
        <p>Todos los procedimientos legales surgidos a partir de los conflictos o controversias se llevarán a cabo en idioma castellano y de conformidad con las leyes de la República de Colombia.</p>
        <h3>20.	 CAMBIOS EN LOS TÉRMINOS Y CONDICIONES DE USO DE LA PLATAFORMA</h3>
        <p>Puede revisar la versión más reciente de los Términos y Condiciones de Uso de la Plataforma en cualquier momento en esta página.</p>
        <p>Nos reservamos el derecho, a nuestra entera discreción, de actualizar, cambiar o sustituir cualquier parte de los presentes Términos y Condiciones de Uso de la Plataforma mediante la publicación de actualizaciones y cambios en nuestro sitio web. Es su responsabilidad consultar nuestro sitio web periódicamente para ver los cambios. El uso que haga de nuestra página web o de la plataforma o su acceso a cualquiera de estos de forma continua después de la publicación de cualquier cambio en los presentes Términos y Condiciones de Uso de la Plataforma, constituye la aceptación de dichos cambios.</p>
        <h3>21.	INFORMACIÓN DE CONTACTO</h3>
        <p>Si tiene preguntas relacionadas sobre los Términos y Condiciones de Uso de la Plataforma las podrán enviar al correo electrónico: soporte@farmenergy.app </p>
        <h3>22.	DECLARACIÓN</h3>
        <p>En este acto y por el presente, el Usuario reconoce haber leído por vía electrónica y comprendido el contenido íntegro de los términos y condiciones de uso del Sitio y de las plataformas. </p>
        <p>En caso de que el Usuario necesite identificar y corregir errores en el envío o en sus datos, podrá contactar a FARM ENERGY S.A. E.S.P. por los medios indicados en el numeral 17 del presente instrumento.</p>
        <p>La información contenida en el presente documento goza de plena validez a la luz del artículo 5 de la Ley 527 de 1999, por medio del cual se reconoce jurídicamente los efectos de los mensajes de datos. </p>
        <p>Las partes de este documento reconocen el uso de firmas electrónicas (mecanismos técnicos que permiten verificar la identidad, y que verifican tanto autenticidad como integridad y como titulares de datos sensibles), autorizan a FARM ENERGY S.A. E.S.P., su tratamiento para finalidades de autenticación y firma del presente documento electrónico y de cualquieras otros documentos que se incorporen en el sitio web o en la plataforma, de conformidad con lo establecido en el Decreto 2364 de 2012.</p>
        <p>Por último, el Usuario acepta y reconoce que el presente documento y cualquier otro que se incorpore en la plataforma, se puede firmar electrónicamente a través del método de Firma Electrónica establecido y provisto por FARM ENERGY S.A. E.S.P., el cual cumple con los criterios de confiabilidad y apropiabilidad y por medio de loes cuales se garantizan los atributos de autenticidad e integridad necesarios de conformidad con la Ley 527 de 1999 y el Decreto 2364 de 2012, compilado en el Decreto Único Reglamentario del sector comercio 1074 de 2015 o cualquier norma que le sustituya o complemente.</p>
      </div>
    </div>
    </div>
    <StickyFooter />
</>);

export default TermsContitions;