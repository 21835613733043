/* eslint-disable max-len */
import React from 'react';
import styles from './performance.module.css';
import bar1 from './../../resources/imgs/bar1.png';
import bar2 from './../../resources/imgs/bar2.png';
import bar3 from './../../resources/imgs/bar3.png';
import bar4 from './../../resources/imgs/bar4.png';
import { Button } from '@material-ui/core';

const Per = () => {
  return (
    <div className={styles.rootPerformance}>
      <div className={styles.performanceLeft}>
        <div className={styles.performanceLeftPercentages}>
          <p>9%</p>
          <p>5%</p>
          <p>0%</p>
        </div>
        <div className={styles.performanceLeftDiagram}>
          <h2 className={styles.title }>Rendimientos esperados</h2>
          <div className={styles.performanceLeftDiagramBar}>
            <img src={bar1} className={styles.img1} alt={'barra'}></img>
            <img src={bar2} className={styles.img2} alt={'barra'}></img>
            <img src={bar3} className={styles.img3} alt={'barra'}></img>
            <img src={bar4} className={styles.img4} alt={'barra'}></img>
          </div>
          <div className={styles.performanceLeftLabel}>
            <p>Cuenta de ahorros</p>
            <p>CDT</p>
            <p>Lorem ipsum</p>
            <p>Farm Energy</p>
          </div>
        </div>
      </div>

      <div className={styles.performanceRight}>
        <h2 className={styles.titleWeb}>Rendimientos esperados</h2>
        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</p>
        <Button className={styles.buttonPerformance} variant={'outlined'} href={'/questions'}>Ir a preguntas frecuentes</Button>
      </div>
    </div>
  );
};

export default Per;
