import React from 'react';
import styles from './cardsComplete.module.css';

const CardsComplete = (props3) => {
    return (
      <div className={styles.complete} >
        <div className={styles.contentComplete}>
          <div className={styles.contentImgComplete}>
            <img src={props3.src} alt={props3.alt}/>
          </div>
          <div className={styles.textComplete}>
            <div className={styles.text1Complete}>
              <h1>{props3.firstText}</h1>
            </div>
            <div className={styles.text2Complete}>
              <h2>{props3.secondText}</h2>
              <p>{props3.thirdText}</p>
            </div>
          </div>
        </div>
      </div>
    );
};

export default CardsComplete;
