import React from 'react';
import Navbar from '../navbar/Navbar';
import StickyFooter from '../footer/StickyFooter';
import Questions from '../Questions/Questions';

const questions = () => {
    return (
      <>
        <Navbar />
        <Questions />
        <StickyFooter />
      </>
    );
};

export default questions;
