/* eslint-disable max-len */
import React from 'react';
import styles from './join.module.css';
import analize from './../../resources/imgs/analize.png';
import invert from './../../resources/imgs/invert.png';
import utilities from './../../resources/imgs/utilities.png';
import enjoy from './../../resources/imgs/enjoy.png';

const BasicInformation = (props2) => {
    return <>
      <div className={styles.BIContentJoin}>
        <div>
          <img className={props2.nameClass} src={props2.src} alt={props2.alt} />
        </div>
        <div className={styles.BItextJoin}>
          <div><h1>{props2.firstText}</h1></div>
          <div style={{ textAlign: 'justify' }}><p>{props2.SecondText}</p></div>
        </div>
      </div>
    </>;
};

const Join = () => {
    return (
      <div className={styles.rootJoin}>
        <div className={styles.titleJoin}>
          <h1>
            Haz parte de Farm Energy
          </h1>
        </div>
        <div className={styles.cardsJoin}>
          <BasicInformation
            nameClass={styles.firstImageJoin} src={analize} alt={'join-1'} firstText={'Analiza'}
            SecondText={`"Te conectamos con altas rentabilidades a través de la participación en la financiación de proyectos de energías renovables".
            Te presentamos herramientas para evaluar la inversión  y verificar ganancias y costos así puedes tomar la mejor decisión para ti.`} />
          <BasicInformation
            nameClass={styles.secondImageJoin} src={invert} alt={'join-1'} firstText={'Invierte'}
            SecondText={`“Explora y conoce todos los detalles de los proyectos en etapa de financiación, escoge con cuál te quieres conectar, decide cuanto quieres invertir y realizalo de forma segura”
            Creemos en la inversión como un propósito y un hábito que te impulsa a crecer.
            `} />
          <BasicInformation
            nameClass={styles.thirdImageJoin} src={utilities} alt={'join-1'} firstText={'Recibe utilidades'}
            SecondText={'“Monitorea la generación energética y la rentabilidad del proyecto en el que participaste y recibe tus ganancias mes a mes, cada vez que la empresa realiza sus pagos de energía.”'} />
          <BasicInformation
            nameClass={styles.fourImageJoin} src={enjoy} alt={'join-1'} firstText={'Disfruta'}
            SecondText={'“Puedes reinvertir las ganancias obtenidas en nuevos proyectos o retirar tu saldo disponible todo al alcance de tu mano”'} />
        </div>
      </div>
    );
};

export default Join;
