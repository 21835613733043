/* eslint-disable */
import React from 'react';
import Navbar from '../navbar/Navbar';
import StickyFooter from '../footer/StickyFooter';

const PrivacyPolicy = () => (<>
  <Navbar />
  <div style={{marginTop: '50px'}}>
    <div style={{padding: '4em 2em'}}>
      <h1 style={{textAlign: 'center'}}>
        POLÍTICA DE PRIVACIDAD Y MANUAL DE
        TRATAMIENTO DE DATOS– FARM ENERGY.</h1>
      <div style={{ padding: '0 200px', textAlign: 'justify'}}>
        <h3>1. Objetivo</h3>
        <p>Dar a conocer a todos nuestros usuarios, clientes, proveedores, accionistas
          y en general todos los agentes que interfieren en el desarrollo de nuestras
          actividades, las políticas de tratamiento de datos, y manejo de cookies garantizando
          el derecho que tienen para conocer, actualizar y rectificar la información que se haya
          recogido sobre ellas y que repose en las Bases de Datos o archivos de
          FARM ENERGY S.A. E.S.P.,(en adelante Farm Energy), en cumplimiento de los derechos,
          libertades y garantías constitucionales a que se refieren los artículo 15 y 20 de la
          Constitución Política de 1991 y las leyes 1581 de 2012, decreto reglamentario 1377 de
          2013 y decreto 1074 de 2015.
        </p>
        <h3>2. Alcance</h3>
        <p>Esta política aplica a nuestros usuarios, clientes, miembros de junta directiva,
          candidatos en proceso de selección, trabajadores, socios comerciales, contratistas,
          proveedores de bienes y servicios y en general terceros que tengan relación directa
          o indirecta con Farm Energy.</p>
        <p>Aplica a todas las bases de datos, sitios web, plataformas y archivos de información
          personal que estén en poder de Farm Energy y que se encuentren dentro del marco contextual
          de la Ley 1581 de 2012, el Decreto 1373 de 2013, Decreto 1081 de 2015 y las demás normas
          que lo modifiquen o reglamenten.</p>
        <h3>3. Definiciones</h3>
        <ul>
          <li>Autorización: Es el consentimiento previo, expreso e informado del titular para llevar a cabo el tratamiento de datos personales.</li>
          <li>Base de datos personales: Conjunto organizado de datos personales que sea objeto de tratamiento, fácilmente accesible, gestionada y actualizada con lo autorización previa del titular del dato.</li>
          <li>Dato personal: información que nos identifica o nos puede hacer identificables, tales como nombre, cédula de ciudadanía, dirección, correo electrónico, número de teléfono, estado civil, su imagen y datos biométricos.</li>
          <li>Clientes: Persona natural o jurídica, pública o privada con las cuales la entidad tiene una relación comercial.</li>
          <li>Dato privado: Es el dato que por su naturaleza íntima o reservada sólo es relevante para el titular.</li>
          <li>Dato público: Es el dato que no es semiprivado, privado o sensible. Son considerados datos públicos, entre otros, los datos relativos al estado civil de las personas, a su profesión u oficio y a su calidad de comerciante o de servidor público. Por su naturaleza, los datos públicos pueden estar contenidos, entre otros, en registros públicos, documentos públicos, gacetas y boletines oficiales y sentencias judiciales debidamente ejecutoriadas que no estén sometidas a reserva.</li>
          <li>Dato semiprivado: Es semiprivado el dato que no tiene naturaleza íntima, reservada, ni pública y cuyo conocimiento o divulgación puede interesar no sólo a su titular sino a cierto sector o grupo de personas o a la sociedad en general, como el dato financiero y crediticio de actividad comercial o de servicios a que se refiere el Título IV de la Ley 1266 de 2008.</li>
          <li>Dato sensible: Información que afecta la intimidad del titular cuyo uso indebido puede generar su discriminación. Entre ellos se encuentran aquellos que revelen las convicciones políticas, religiosas o filosóficas, la pertenencia a sindicatos, organizaciones sociales, así como los datos relativos a la salud, a la vida sexual y datos biométricos.</li>
          <li>Encargado del tratamiento: Persona natural o jurídica, Pública o privada, que por sí misma o en asocio con otros, realice el tratamiento de datos personales por cuenta del responsable del tratamiento. Para el caso en concreto, será cualquier tercero designado por la entidad que realice el tratamiento de datos por su cuenta y en nombre de la entidad. En los eventos en que el responsable no ejerza como encargado de la Base de Datos, se identificará expresamente quien será el encargado.</li>
          <li>Titular: Persona natural o jurídica cuyos datos personales sean objeto de tratamiento.</li>
          <li>Tratamiento de datos: Cualquier operación o conjunto de operaciones sobre datos personales, tales como la recolección, almacenamiento, uso, circulación o supresión de los mismos.</li>
          <li>Responsable del tratamiento: Persona natural o jurídica, pública o privada, que por sí misma o en asocio con otros, decida sobre la base de datos y/o el tratamiento de los mismos.</li>
          <li>Reclamo: Solicitud del titular de la información, de sus causahabientes, o de quien este autorice para corregir, actualizar o suprimir sus datos personales o para revocar la autorización en los casos establecidos en la ley.</li>
          <li>Transferencia: La transferencia de datos se lleva a cabo cuando el responsable y/o encargado del tratamiento de datos personales, ubicado en Colombia, envía la información o los Datos Personales a un receptor ubicado dentro o fuera de Colombia, que a su vez es Responsable del Tratamiento.</li>
          <li>Transmisión: Tratamiento de datos personales que implica la comunicación de los mismos dentro o fuera del territorio colombiano, cuando tenga por objeto la realización de un tratamiento por el encargado por cuenta del responsable.</li>
        </ul>
        <h3>4. Principios aplicables al tratamiento de datos personales</h3>
        <p>En el tratamiento de los datos personales, Farm Energy aplicará los principios que se definen a continuación, los cuales conforman las reglas a seguir en cuanto al manejo, uso, tratamiento, transferencia, almacenamiento e intercambio, de datos personales:</p>
        <ul>
          <li>Legalidad: El tratamiento de datos personales se realizará de acuerdo a las disposiciones legales aplicables vigentes (Ley Estatutaria 1581 de 2012 y sus decretos reglamentarios).</li>
          <li>Finalidad: Los datos personales serán utilizados para una finalidad legítima y un propósito específico, los cuales deberán ser informados al titular. El titular deberá ser informado de manera previa, clara y suficiente acerca de la finalidad de la información suministrada.</li>
          <li>Libertad: La recolección de los datos personales solo podrá ejercerse con la Autorización, previa, expresa e informada del titular. Los datos personales no podrán ser obtenidos, tratados o divulgados sin autorización del titular salvo mandato legal o judicial que releve el consentimiento del titular.</li>
          <li>Veracidad o Calidad: La información sujeta al tratamiento de datos personales debe ser veraz, completa, exacta, actualizada, comprobable y comprensible.</li>
          <li>Transparencia: En el tratamiento de datos personales, se garantiza el derecho del titular a obtener en cualquier momento y sin restricciones, información acerca de la existencia de datos que le conciernan.</li>
          <li>Acceso y circulación restringida: El tratamiento de datos personales solo podrá realizarse por las personas autorizadas de parte del titular y/o por las personas previstas en la ley. El titular con su autorización, facultará a Farm Energy a la transferencia y transmisión de datos personales para el cumplimiento de las finalidades mencionadas en la presente política.</li>
          <li>Seguridad: El tratamiento de datos personales contará con todas las medidas de seguridad técnicas o administrativas necesarias para evitar la pérdida, adulteración, consulta, uso o acceso no autorizado o fraudulento de los mismos.</li>
          <li>Confidencialidad: Todas las personas que intervengan en el tratamiento de datos personales, tienen la obligación profesional de guardar y mantener reserva de tales datos, salvo las excepciones legales. Esta obligación subsiste aun finalizada la relación que dio origen a la recolección y tratamiento del dato. Farm Energy establecerá en todos sus contratos, cláusulas de protección de datos personales.</li>
        </ul>
        <h3>5. Tratamiento de la Información Personal</h3>
        <p>Mediante el ingreso al Sitio web, a las plataformas y/o su registro, el usuario autoriza a FARM ENERGY S.A. E.S.P. a usar y tratar los datos personales por él ingresados al Sitio, para los fines establecidos en el Sitio y en especial, con el fin de permitirle crear una base de datos de los usuarios registrados, enviar información comercial, publicitaria, técnica de FARM ENERGY S.A. E.S.P., y/o sus productos y/o sus servicios a los Usuarios, construir estrategias comerciales con los usuarios registrados y la información por ellos reportada, uso de la información en procesos comerciales operativos de FARM ENERGY S.A. E.S.P., la programación y envío de obsequios, postales y cualquier material publicitario así como para cualquier otro fin comercial que permita desarrollar relación comercial con los usuarios para los servicios que brinda FARM ENERGY S.A. E.S.P.. 
        La información de los usuarios y demás contenidos publicados en el Sitio, que tengan la calidad de datos personales de acuerdo con lo establecido en la Ley 1581 de 2012, serán tratados como confidenciales y protegidos de acuerdo a la Ley 1581 de 2012 sus demás normas concordantes y complementarias y la política de protección de Datos Personales de FARM ENERGY S.A. E.S.P. 
        FARM ENERGY S.A. E.S.P. tomará medidas razonables lógicas, administrativas y físicas para proteger del acceso no autorizado de terceros a la información personal obtenida a través del Sitio, así como para evitar la adulteración, pérdida, consulta o uso no autorizado o fraudulento por parte de terceros de la información personal. Sin embargo, FARM ENERGY S.A. E.S.P. no se hace responsables por el ingreso no autorizado de terceros al Sitio y el acceso a la información personal de los usuarios y visitantes, vulnerando las medidas a través de internet. En estos eventos FARM ENERGY S.A. E.S.P. gestionará el incidente de seguridad de conformidad con los lineamientos establecidos por la ley aplicable.
        El usuario autoriza a FARM ENERGY S.A. E.S.P. a dar a conocer su información personal a las autoridades competentes en cumplimiento de la ley o de orden administrativa, y a conservar dicha información para las finalidades acá establecidas, pudiendo conservarla hasta que la finalidad para la cual hayan sido obtenidos tales datos se haya cumplido. 
        El usuario podrá cada que lo desee ingresar a su perfil para hacer los cambios necesarios en sus datos personales cuando estos deban ser actualizados o cuando desee eliminarlos. El usuario podrá ejercer derechos reconocidos por el artículo 8 de la Ley 1581 de 2012, respecto de los datos personales que haya ingresado a través del Sitio.
        </p>
        <p>
        Farm Energy, en calidad de responsable del tratamiento de datos personales y con el fin de ejecutar adecuadamente sus actividades comerciales y fortalecer las relaciones con terceros, recopila, almacena, utiliza, circula, transmite, transfiere, actualiza, rectifica y suprime datos personales, incluyendo datos sensibles, correspondientes a personas naturales con quienes tiene o ha tenido relación, tales como, usuarios, trabajadores, clientes, proveedores, distribuidores, accionistas, acreedores y deudores a nivel nacional e internacional.
        </p>
        <p>Igualmente, Farm Energy protege la privacidad de los datos sensibles de los titulares de la información, entendiendo estos como aquellos que afectan la intimidad del titular o cuyo uso indebido puede generar su discriminación, tales como aquellos que revelen el origen racial o étnico, la orientación política, las convicciones religiosas o filosóficas, la pertenencia a sindicatos, organizaciones sociales, de derechos humanos o que promueva intereses de cualquier partido político o que garanticen los derechos y garantías de partidos políticos de oposición así como los datos relativos a la salud, a la vida sexual y los datos biométricos a nivel nacional e internacional.</p>
        <h4>5.1 Tratamiento de Datos.</h4>

        <p>De acuerdo con los límites establecidos por la ley, Farm Energy deberá tener en cuenta:</p>
        <h5>5.1.1. El titular de los datos otorgue su autorización expresa para realizar el tratamiento de los mismos, salvo, en los casos que por ley no sea requerida dicha autorización.</h5>
        <h5>5.1.2. El tratamiento sea ineludible, a fin de velar por el interés vital del titular de los datos cuando éste se encuentre física o jurídicamente incapacitado. En estos eventos, los representantes legales deberán otorgar su autorización.</h5>
        <h5>5.1.3 El tratamiento se efectúe en razón de actividades legítimas y con las respectivas garantías por parte de una fundación, ONG, asociación o cualquier otra entidad sin ánimo de lucro, que tenga finalidad política, filosófica, religiosa o sindical, siempre y cuando solamente hagan referencia a sus miembros o a las personas que mantengan contacto regular por razón de la finalidad. En este caso, los datos sólo se pueden enviar a terceros con autorización del titular.</h5>
        <h5>5.1.4 5.1.4. En caso de solicitud de los datos por parte de entidades públicas, estos deben ser necesarios para el reconocimiento, ejercicio o defensa de un derecho en un proceso judicial.</h5>
        <h5>5.1.5 Implementar las medidas necesarias cuando el tratamiento de datos tenga una finalidad histórica, estadística o científica. En este evento deberán adoptarse las medidas conducentes a la supresión de identidad de los titulares.</h5>
        <h4>5.2. Tratamiento de los datos personales de niños, niñas y adolescentes.</h4>
        <p>El tratamiento de este tipo de datos personales requiere de un especial respeto a los derechos prevalentes de los niños, niñas y adolescentes.</p>
        <p>El uso de los datos personales de los niños, niñas y adolescentes queda prohibido en Farm Energy salvo cuando se trate de datos de naturaleza pública, y cuando dicho tratamiento cumpla con los parámetros y requisitos de responder y respetar los derechos fundamentales y el interés superior de los niños, niñas y adolescentes.</p>
        <p>En virtud de lo anterior y en caso de que Farm Energy llegare a tratar datos personales de niños, niñas y adolescentes, incluidos datos sensibles, deberá contar con autorización expresa de sus padres o representantes legales.</p>
        <p>En el evento en el que un menor suministre sus datos sin presencia de sus padres, Farm Energy utilizará la información recaudada del menor para contactar al padre o tutor para obtener la respectiva autorización de éste. Si no es posible contactarlo dentro de 5 días hábiles o no se obtiene la autorización por parte del padre o del tutor, Farm Energy procederá a eliminar la información del menor de las bases de datos de Farm Energy.</p>
        <h3>6. Finalidades del Tratamiento de datos personales</h3>
        <p>Se establecen los siguientes fines para el tratamiento de la información personal según el perfil y/o rol.</p>
        <h4>6.1. Generales</h4>
        <ol type="a">
          <li>Procesamiento y administración de los datos como cliente o usuario de Farm Energy *</li>
          <li>Ofrecer a través de medios propios o conjuntamente con terceros Información de nuevos lanzamientos de productos y/o beneficios.</li>
          <li>Información adicional que beneficie la operación y/o manipulación de nuestros productos en caso de inconvenientes o fallas.</li>
          <li>Cumplir con la notificación de información de interés cuando lo haya solicitado.</li>
          <li>Estudiar y almacenar información asociada a solicitudes de alguno de nuestros productos que como futuro cliente debemos conocer para la establecer la relación comercial.</li>
          <li>Atender solicitudes, quejas o reclamos.</li>
          <li>Enviar comunicaciones relacionadas con las actividades comerciales de Farm Energy, noticias e información útil para el ejercicio profesional, productos, ofertas, novedades, invitaciones a eventos, ofertas de empleo, propaganda, publicidad y/o encuestas sobre nuestros productos servicios y/o los productos y servicios de nuestros socios comerciales.</li>
          <li>Fines estadísticos, mercadeo o análisis relacional de información, investigación, innovación y desarrollo de nuevos productos y/o servicios.</li>
          <li>Compartir, incluyendo la transferencia y transmisión de sus datos personales, a terceros países para los fines relacionados con la operación, de conformidad con el cumplimiento de los estándares fijados por la SIC sobre la materia.</li>
          <li>Uso para asuntos de seguridad y protección al interior de las instalaciones de Farm Energy.</li>
          <li>Envío de correspondencia.</li>
          <li>Usar en fines legales, administrativos y comerciales.</li>
          <li>Reporte a entidades de control</li>
          <li>Usar para fines comerciales los correos electrónicos, mensajes de texto (SMS y/o MMS) o cualquier medio análogo o digital de los titulares.</li>
          <li>Uso y tratamiento de la imagen para incluirlos sobre fotografías; procedimientos análogos a la fotografía; producciones audiovisuales (Videos); así como de los Derechos de Autor; los Derechos Conexos y en general todos aquellos derechos de propiedad intelectual que tengan que ver con el derecho de imagen.</li>
        </ol>
        <h4>6.2. Específicas</h4>
        <ol type="a">
          <li>Accionistas y Junta Directiva: Atención, control, almacenamiento, aseguramiento, comunicación, acreditación, capacitación, registro, tramitación, procesamiento, autorización, consolidación, análisis de actividades y estrategias en las cuales se relacionan con Farm Energy.</li>
          <li>Empleados, candidatos, funcionarios y /o pensionados: Comunicación, almacenamiento, capacitación, registro, procesamiento, autorización, consolidación, análisis, reporte e informaciones y actividades en las cuales se relacionan con Farm Energy.</li>
          <li>Contratistas y sus empleados: Registro, almacenamiento, consolidación, formalización, ejecución de contratos, difusión y control de la información en la cual se relacionan o vinculan con Farm Energy.</li>
          <li>Clientes, usuarios y proveedores: Comunicación, almacenamiento, registro, actualización, mercadeo, relacionamiento, acreditación, atención, aseguramiento de la información o actuación relacionada con Farm Energy.</li>
        </ol>
        <h3>7. Derechos del titular.</h3>
        <p>Farm Energy en todo tratamiento de datos personales será respetuosa de los derechos de los titulares. Para efectos de la presente política, el titular de los datos personales tendrá los siguientes derechos:</p>
        <ul>
          <li>Conocer, actualizar y rectificar sus datos personales frente a los responsables del tratamiento o encargados del tratamiento Este derecho se podrá ejercer, entre otros frente a datos parciales, inexactos, incompletos, fraccionados, que induzcan a error, o aquellos cuyo tratamiento esté expresamente prohibido o no haya sido autorizado con las normas que reglamenten, adicionen, ejecuten, complementen, modifiquen, supriman o deroguen.</li>
          <li>Solicitar prueba de la autorización otorgada a Farm Energy salvo cuando expresamente se exceptúe como requisito para el tratamiento.</li>
          <li>Ser informado por el responsable del tratamiento o el encargado del tratamiento, previa solicitud del titular, respecto del uso dado a sus datos personales.</li>
          <li>Presentar ante la Superintendencia de Industria y Comercio quejas por infracciones, según lo dispuesto en la Ley 1581 de 2012 y las demás normas que la modifiquen, adicionen, complementen, modifiquen, supriman o deroguen.</li>
          <li>Revocar en cualquier momento la autorización y/o solicitar la supresión del dato, cuando en el tratamiento no se respeten los principios, derechos y garantías constitucionales y legales. La revocatoria y/o supresión procederá cuando la Superintendencia de Industria y Comercio determine que, en el proceso de Tratamiento de la información, Farm Energy ha incurrido en conductas contrarias a ley y a la Constitución.</li>
          <li>Acceder en forma gratuita a sus datos personales que voluntariamente decidió compartir con Farm Energy, que hayan sido objeto de tratamiento.</li>
        </ul>
        <p>De acuerdo con el objeto de esta declaración el titular, en relación con sus datos personales no públicos, tiene derecho al conocimiento, acceso, rectificación, actualización, revocatoria y/o supresión de sus datos personales en cualquier momento.</p>
        <h3>8. Negativa de la sociedad para acceder, rectificar o cancelar a los datos personales</h3>
        <p>Farm Energy podrá negar el acceso total o parcial a los datos personales o la realización de la rectificación, cancelación u oposición al tratamiento de los datos personales, en el evento en que el solicitante no sea el titular o su representante debidamente acreditado; cuando se lesionen los derechos de un tercero; cuando la rectificación haya sido previamente realizada, cuando no se encuentren datos personales del solicitante en las bases de datos de la sociedad; cuando exista impedimento legal o resolución de una autoridad.</p>
        <p>Conforme a lo establecido en el artículo 9 del Decreto 1377 de 2013, la sociedad no estará obligada a suprimir los datos personales ni a acceder a la solicitud de revocatoria de la autorización cuando se cumplan los presupuestos allí establecidos.</p>
        <h3>9. Autorización del titular</h3>
        <p>Todo tratamiento que Farm Energy haga de información personal requiere la autorización previa e informada del titular, la cual deberá ser obtenida por cualquier medio que pueda ser objeto de consulta posterior. La autorización no será necesaria en las excepciones previstas en la ley, a manera enunciativa y sin perjuicio de las normas modifiquen, adicionen o complementen, la autorización no será necesaria en los siguientes casos:</p>
        <ol type="a">
          <li>Información requerida por una entidad pública o administrativa en ejercicio de sus funciones legales o por orden judicial.</li>
          <li>Datos de naturaleza pública.</li>
          <li>Casos de urgencia médica sanitaria.</li>
          <li>Tratamiento de información autorizado por la ley para fines históricos, estadísticos o científicos.</li>
          <li>Datos relacionados con el Registro Civil de las personas.</li>
        </ol> 
        <h4>9.1. Deber de informar al titular</h4>       
        <p>El responsable del tratamiento, al momento de solicitar al titular la autorización, deberá informarle de manera clara y expresa lo siguiente:</p>
        <ol tpye="a">
          <li>El tratamiento al cual serán sometidos sus datos personales y la finalidad del mismo;</li>
          <li>El carácter facultativo de la respuesta a las preguntas que le sean hechas, cuando estas versen sobre datos sensibles o sobre los datos de las niñas, niños y adolescentes;</li>
          <li>Los derechos que le asisten como titular;</li>
          <li>La identificación, dirección física o electrónica del responsable del tratamiento.</li>
        </ol>
        <h4>9.2. Personas a quienes se les puede suministrar la información</h4>
        <p>Farm Energy podrá suministrar la información a las siguientes personas:</p>
        <ol type="a">
          <li>A los Titulares, sus causahabientes o sus representantes legales;</li>
          <li>A las entidades públicas o administrativas en ejercicio de sus funciones legales o de orden judicial;</li>
          <li>A los terceros autorizados por el Titular o por la ley.</li>
        </ol>
        <h3>10. Obligaciones</h3>
        <h4>10.1. Obligaciones de Farm Energy</h4>
        <ol type="a">
          <li>Garantizar al titular, en todo tiempo, el pleno y efectivo ejercicio del derecho de tratamiento de datos personales.</li>
          <li>Solicitar y conservar, en las condiciones previstas en la ley 1581 de 2012 y los decretos reglamentarios y demás normas que los modifiquen o reformen, copia de la respectiva autorización otorgada por el titular.</li>
          <li>Informar debidamente al titular sobre la finalidad de la recolección y los derechos que le asisten por virtud de la autorización otorgada.</li>
          <li>Conservar la información bajo las condiciones de seguridad necesarias para impedir su adulteración, pérdida, consulta, uso o acceso no autorizado o fraudulento.</li>
          <li>Garantizar que la información que se suministre al encargado del tratamiento sea veraz, completa, exacta, actualizada, comprobable y comprensible.</li>
          <li>Actualizar la información, comunicando de forma oportuna al encargado del tratamiento, todas las novedades respecto de los datos que previamente le haya suministrado y adoptar las demás medidas necesarias para que la información suministrada a éste se mantenga actualizada.</li>
          <li>Rectificar la información cuando sea incorrecta y comunicar, mediante el medio de comunicación disponible y más expedito, lo pertinente al encargado del tratamiento.</li>
          <li>Suministrar al encargado del tratamiento, según el caso, únicamente datos cuyo tratamiento esté previamente autorizado de conformidad con lo previsto en la ley.</li>
          <li>Exigir al encargado del tratamiento en todo momento, el respeto a las condiciones de seguridad y privacidad de la información del Titular.</li>
          <li>Tramitar las consultas y reclamos formulados en los términos señalados en la ley.</li>
          <li>Adoptar un manual interno de políticas y procedimientos para garantizar el adecuado cumplimiento de la ley y en especial, para la atención de consultas y reclamos.</li>
          <li>Informar, a través de los medios de comunicación disponibles y más expeditos, al encargado del tratamiento cuando determinada información se encuentra en discusión por parte del titular, una vez se haya presentado la reclamación y no haya finalizado el trámite respectivo.</li>
          <li>Informar a solicitud del titular sobre el uso dado a sus datos.</li>
          <li>Informar a la autoridad de protección de datos cuando se presenten violaciones a los códigos de seguridad y existan riesgos en la administración de la información de los titulares.</li>
          <li>Cumplir las instrucciones y requerimientos que imparta la Superintendencia de Industria y Comercio.</li>
        </ol>
        <h4>10.2. Deberes de los responsables y encargados del tratamiento de la información.</h4>
        <ol>
          <li>Garantizar al titular, en todo tiempo el pleno y efectivo ejercicio del derecho de hábeas data.</li>
          <li>
            Conservar la información bajo las condiciones amparadas en las siguientes Políticas de Farm Energy:
            <ul>
              <li>Política de seguridad de Base de Datos y</li>
              <li>o Política de Seguridad de la Información.</li>
            </ul>
          </li>
          <li>Realizar oportunamente la actualización, rectificación o supresión de los datos en los términos de ley.</li>
          <li>Actualizar la información reportada por los responsables del tratamiento dentro de los quince (15) días hábiles contados a partir de su recibo.</li>
          <li>Tramitar las consultas y los reclamos formulados por los titulares en los términos señalados en la ley.</li>
          <li>Adoptar un manual interno de políticas y procedimientos para garantizar el adecuado cumplimiento de la ley y, en especial, para la atención de consultas y reclamos por parte de los titulares.</li>
          <li>Registrar en la base de datos la leyenda "reclamo en trámite" en la forma en que se regula en la ley.</li>
          <li>Insertar en la base de datos la leyenda "información en discusión judicial" una vez notificado por parte de la autoridad competente sobre procesos judiciales relacionados con la calidad del dato personal.</li>
          <li>Abstenerse de circular información que esté siendo controvertida por el titular y cuyo bloqueo haya sido ordenado por la Superintendencia de Industria y Comercio.</li>
          <li>Permitir el acceso a la información únicamente a las personas que pueden tener acceso a ella.</li>
          <li>Informar a la Superintendencia de Industria y Comercio cuando se presenten violaciones a los códigos de seguridad y existan riesgos en la administración de la información de los titulares.</li>
          <li>Cumplir las instrucciones y requerimientos que imparta la Superintendencia de Industria y Comercio.</li>
        </ol>
        <h3>11. Atención de peticiones, consultas y reclamos</h3>
        <h4>11.1. Área Competente.</h4>
        <p>Farm Energy atenderá todas las peticiones, consultas y/o reclamos del titular de la información, relacionadas con los derechos establecidos en la ley para conocer, actualizar, rectificar y suprimir o revocar la autorización que había otorgado a Farm Energy frente a sus datos personales, las áreas responsables en Farm Energy para dar atención son:</p>
        <h5>11.1.1. Servicio al Cliente, para peticiones quejas y/o reclamos, mediante el correo: soporte@farmenergy.app</h5>
        <h4>11.2. Procedimiento para el ejercicio de los derechos del titular de la información</h4>
        <p>Los titulares o sus causahabientes podrán consultar la información personal del titular que repose en la base de datos de Farm Energy, solicitar corrección, actualización o supresión o reclamo. Sus derechos podrán ser ejercidos a través de:</p>
        <ul>
          <li>Envío de correo electrónico a soporte@farmenergy.app </li>
          <li>Comunicación telefónica, en la ciudad de Bogotá (Colombia) al teléfono (+57) 3378157</li>
        </ul>
        <h4>11.3. Término para obtención de respuesta</h4>
        <p>Luego de recibida la solicitud por los medios establecidos, Farm Energy tendrá diez (10) días hábiles (Desde la recepción del requerimiento) para dar contestación al titular de la información que allegue solicitud alguna a Farm Energy. Cuando no fuere posible atender la consulta dentro de dicho término, se informará al interesado el motivo de la demora y la fecha en que se atenderá su consulta, la cual en ningún caso podrá superar los cinco (5) días hábiles siguientes al vencimiento del primer término.</p>
        <h3>12. Seguridad de los datos personales</h3>
        <p>Farm Energy, en aplicación al principio de seguridad en el tratamiento de datos personales, proporcionará las medidas administrativas y tecnológicas que sean necesarias para proveer seguridad a los registros, evitando su adulteración, pérdida, consulta, uso o acceso no autorizado o fraudulento. No obstante, lo anterior, Farm Energy no garantiza la seguridad total de su información ni se hace responsable por cualquier consecuencia de fallas técnicas o del ingreso indebido por parte de terceros a la base de datos o a los archivos en los que reposan los datos personales objeto de tratamiento.</p>
        <p>FARM ENERGY S.A. E.S.P. guarda la información obtenida a través del Sitio y a través de otros mecanismos de recolección de datos de sus Usuarios en sus bases de datos, con el objetivo de proveer un adecuado servicio a los Usuarios Registrados y asegurar la integridad de los datos y de prevenir los fraudes de terceros.
En todo caso bajo ninguna circunstancia FARM ENERGY S.A. E.S.P. o los terceros que éste contrate almacenarán claves de acceso o nombres de usuario de otros sitios web que el usuario ingrese en el sitio web o en la Plataforma de FARM ENERGY S.A. E.S.P. 
Sin perjuicio de lo señalado en los puntos anteriores, el Usuario comprende que las medidas de seguridad en Internet no son inquebrantables y asume los riesgos que se deriven de revelaciones fuera del control de FARM ENERGY S.A. E.S.P. que se generen como producto de ataques informáticos que no pudieren ser resistidos con los sistemas y conocimientos tecnológicos comúnmente conocidos.
</p>
        <h3>13. Transferencia y Transmisión de datos personales</h3>
        <p>Farm Energy podrá entregar los datos personales a terceros no vinculados a la entidad cuando:</p>
        <ul>
          <li>Se trate de contratistas en ejecución de cualquier tipo de contratos para el desarrollo de las actividades de la sociedad.</li>
          <li>Por transferencia a cualquier título de cualquier línea de negocio con la que se relaciona la información.</li>
        </ul>
        <p>En todo caso, cuando Farm Energy desee enviar o transmitir datos a uno o varios encargados ubicados dentro o fuera del territorio de la República de Colombia, establecerá cláusulas contractuales o celebrará un contrato de transmisión de datos personales en el que, entre otros, se pacte lo siguiente:</p>
        <ol type="a">
          <li>Los alcances y finalidades del tratamiento.</li>
          <li>Las actividades que el encargado realizará en nombre de la sociedad.</li>
          <li>Las obligaciones que debe cumplir el encargado respecto del titular del dato y Farm Energy.</li>
          <li>El deber del encargado de tratar los datos de acuerdo con la finalidad autorizada para el mismo y observando los principios establecidos en la ley colombiana y la presente política.</li>
          <li>La obligación del encargado de proteger adecuadamente los datos personales y las bases de datos, así como de guardar confidencialidad respecto del tratamiento de los datos transmitidos.</li>
          <li>Una descripción de las medidas de seguridad concretas que van a ser adoptadas tanto por la sociedad como por el encargado de los datos en su lugar de destino.</li>
        </ol>
        <p>Farm Energy no solicitará la autorización cuando la transferencia internacional de datos se encuentre amparada en alguna de las excepciones previstas en la ley y sus decretos reglamentarios.</p>
        <h3>14. Normatividad vigente</h3>
        <p>La Política de Tratamiento de datos personales, rige a partir del 22 de junio de 2017 y estará bajo lo estipulado en la Ley 1581 de 2012 y el Decreto 1377 de 2013 y demás normas concordantes que lo modifiquen o reformen. Igualmente, la vigencia de esta política estará sometida a la finalidad del tratamiento de los datos personales Compañía.</p>
        <h3>15. Autorización Expresa de Uso de Cookies</h3>
        <p>FARM ENERGY S.A. E.S.P. utiliza cookies para registrar las preferencias de los usuarios, analizar el tráfico de este sitio web, evaluar y mejorar el contenido y funciones de este sitio web y optimizar el diseño y los contenidos del mismo. 
Las cookies son pequeños archivos de texto enviados por un sitio web y que se guardan en el disco duro del computador. La mayoría de los navegadores aceptan cookies automáticamente. El usuario siempre puede configurar su navegador para rechazar todas las cookies de este u otros sitios web que visite. 
Si el usuario opta por no aceptar cookies, igualmente podrá usar este sitio web, sin embargo, es posible que algunas partes de este sitio web no funcionen correctamente o se ejecuten lentamente si elige rechazar las cookies. 
Al usar este sitio web o las plataformas de FARM ENERGY S.A. E.S.P. y no deshabilitar las cookies, el usuario acepta su uso para los propósitos descritos.</p>
<p>Farm Energy necesita implementar cookies propias y de terceros, para ofrecer sus servicios web y adicionalmente prestar un servicio de calidad a todos los usuarios que utilicen dichos servicios, tenga en cuenta que, si decide no utilizar algunas cookies, este sitio web puede no funcionar adecuadamente, presentar fallas o impedir la operación de algunas de sus características, afectando la experiencia de usuario.</p>
        <h4>15.1. Definición y tipo de cookies</h4>
        <p>Una cookie es un pequeño fragmento de texto que se almacena en su navegador cuando visita casi cualquier página web. Su utilidad es que la web sea capaz de recordar su visita cuando vuelva a navegar por esa página. Las cookies suelen almacenar información de carácter técnico, preferencias personales, personalización de contenidos, estadísticas de uso, enlaces a redes sociales, acceso a cuentas de usuario, etc. Las cookies permiten a esa página, entre otras cosas, almacenar y recuperar información sobre sus hábitos de navegación o de su equipo, y dependiendo de la información que contengan y de la forma de uso del equipo, pueden utilizarse para reconocer al usuario y facilitar su navegación en la página web.
El navegador del usuario almacena cookies en el disco duro ocupando un espacio de memoria mínimo y sin perjudicar al ordenador. La mayoría de las cookies se borran del disco duro al finalizar la sesión de navegador (las denominadas cookies de sesión). Los navegadores más conocidos (como Google Chrome, Mozilla Firefox o Internet Explorer) usan constantemente las cookies para garantizar una mejor y más eficiente experiencia de usuario. Típicamente, los navegadores ofrecen la posibilidad de ser configurados para permitir o impedir la recolección de ciertos tipos de cookies. Los datos que guardan son de carácter técnico, estadísticos, preferencias personales, personalización de contenidos, etc.
</p>
<p>Existen diferentes tipos de cookies, las cuales pueden ser clasificadas en función a quien las ha creado, cuanto tiempo duran y, finalmente, que finalidad tienen. A saber:</p>
<ol type="i">
  <li>Cookies técnicas: Son aquellas que permiten al usuario la navegación a través de una página web, plataforma o aplicación y la utilización de las diferentes opciones o servicios que en ella existan como, por ejemplo, controlar el tráfico y la comunicación de datos, identificar la sesión, acceder a partes de acceso restringido, recordar los elementos que integran un pedido, realizar el proceso de compra de un pedido, realizar la solicitud de inscripción o participación en un evento, utilizar elementos de seguridad durante la navegación, almacenar contenidos para la difusión de videos o sonido o compartir contenidos a través de redes sociales.</li>
  <li>Cookies de personalización: Son aquellas que permiten al usuario acceder al servicio con algunas características de carácter general predefinidas en función de una serie de criterios en el terminal del usuario como por ejemplo serian el idioma, el tipo de navegador a través del cual accede al servicio, la configuración regional desde donde accede al servicio, etc.</li>
  <li>Cookies de análisis: Son aquellas que bien tratadas por nosotros o por terceros, nos permiten cuantificar el número de usuarios y así realizar la medición y análisis estadístico de la utilización que hacen los usuarios del servicio ofertado. Para ello se analiza su navegación en nuestra página web con el fin de mejorar la oferta de productos o servicios que le ofrecemos.</li>
  <li>Cookies publicitarias: Son aquellas que, bien tratadas por nosotros o por terceros, nos permiten gestionar de la forma más eficaz posible la oferta de los espacios publicitarios que hay en la página web, adecuando el contenido del anuncio al contenido del servicio solicitado o al uso que realice de nuestra página web. Para ello podemos analizar sus hábitos de navegación en Internet y podemos mostrarle publicidad relacionada con su perfil de navegación.</li>
  <li>Cookies de publicidad comportamental: Son aquellas que permiten la gestión, de la forma más eficaz posible, de los espacios publicitarios que, en su caso, el editor haya incluido en una página web, aplicación o plataforma desde la que presta el servicio solicitado. Estas cookies almacenan información del comportamiento de los usuarios obtenida a través de la observación continuada de sus hábitos de navegación, lo que permite desarrollar un perfil específico para mostrar publicidad en función del mismo.</li>
  <li>Cookies de terceros: Esta web puede utilizar servicios de terceros que, recopilarán información con fines estadísticos, de uso del sitio por parte del usuario y para la prestación de otros servicios relacionados con la actividad del sitio web y otros servicios de Internet.</li>
</ol>
<h4>15.2. Normatividad Vigente</h4>
<p>En Colombia, no existen cuerpos normativos que hayan definido las cookies o su regulación. No obstante, dentro de nuestras buenas prácticas corporativas y atendiendo a la aplicación de los deberes consagrados en la normativa colombiana de protección de datos (que incluyen la Ley 1581 de 2012, el Decreto 1377 de 2013, entre otras) hemos desarrollado la presente política, en especial atención con los principios de finalidad, libertad, transparencia y veracidad de los datos.</p>
<h4>15.3. Aceptación del uso de cookies</h4>
<p>Si el Usuario ha manifestado mediante el botón de [Aceptar y continuar], que se encuentra en la notificación desplegable en el sitio web, que está conforme con el tratamiento de cookies y en ese sentido, permanece en el mismo y/o no deshabilita en su navegador el uso de cookies, entendemos que ha consentido su uso. No obstante, en cualquier momento, podrá revocarlo y desactivar las cookies, eliminando las almacenadas en su equipo, a través de los ajustes y configuraciones de su navegador.</p>
<h3>16. Terminación</h3>
<p>Con el fin de que este servicio sea una fuente valiosa de información, recibiremos sus comentarios y sugerencias a través de:</p>
<ul>
  <li>Nuestra página web:  https://farmenergy.app/ </li>
  <li>Correo electrónico: soporte@farmenergy.app</li>
  <li>Llamada telefónica en la ciudad de Bogotá al (+57) 601 3378157</li>
  <li>Nuestras instalaciones ubicadas en: Cra. 9 No. 59 - 43 en Bogotá D.C.</li>
</ul>
      </div>
    </div>
  </div>
  <StickyFooter />
</>);

export default PrivacyPolicy;
